.password-title {
    font-size: clamp(18px, 1.2vw, 24px);
    font-weight: 600;
}

.form-container {
    margin: 100px auto;
    width: 100%;
    position: relative;
}

.text-error {
    color: #f00e0e;
    font-size: 0.8em;
    font-weight: 600;
    text-align: left;
}

#passwordForm {
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%) !important;
    border-radius: 10px;
}

#submit-password {
    color: white;
    background-color: #f00e0e;
    cursor: pointer;
    text-align: center;
    border-radius: 10px;
    transition: 0.2s transform ease-in-out;
}

.password-form-success {
    text-align: center;
    font-size: 24px;
    margin-top: 80px;
    color: #000;
}

.form-inputs {
    margin-bottom: 0.5rem;
}

.text-errors {
    font-size: 1.3rem;
    margin-top: 0.5rem;
    color: #f00e0e !important;
}

.form-label {
    display: inline-block;
    font-size: 0.8rem;
    margin-bottom: 6px;
    color: #fff;
}

.form-input {
    display: block;
    padding-left: 10px;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 100%;
    border: none;

    &::placeholder {
        color: #595959;
        font-size: 12px;
    }
}