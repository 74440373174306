h1 {
    font-size: 38px;
    font-weight: 400;
}

.is-active {
    background-color: #e50019;
    color: white !important;
}

.menu-item-img {
    height: 24px;
}

.sideMenuRound {
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    border-radius: 10px;


    .menu-items {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;

        .web-nav-link {
            color: black;
            font-size: clamp(14px, 1.5vw, 22px);
            font-weight: 500;
            text-align: center;
            text-decoration: none;
            white-space: nowrap;
            width: 100%;
            padding: 1rem 1.4rem;
            margin: 0.4rem 0.4rem;
            border-radius: 0.5em;

            &:not(.is-active):hover {
                background-color: rgba(229, 0, 25, 0.1);
            }

            & button {
                font-size: clamp(14px, 1.5vw, 22px);
                text-align: left;
                width: 100%;
                padding: 1rem;
                border-radius: 0.5em;
                margin-bottom: 0.5em;

                @media (min-width: 768px) {
                    margin-bottom: 1.5em;
                }
            }

            & button:not(.is-active):hover {
                cursor: pointer;
                background-color: azure;
            }

            @media screen and (min-width:1199px) {
                text-align: left;
            }
        }

        #dashboard-password-btn,
        #dashboard-address-btn,
        #dashboard-profile-btn,
        #dashboard-soldstock-btn {
            @media screen and (max-width: 767px) {
                display: none;
            }
        }

        @media screen and (min-width:350px) {
            flex-flow: nowrap;
            flex-direction: row;
        }

        @media screen and (max-width: 768px) {}

        @media screen and (min-width: 1199px) {
            flex-direction: column;
        }
    }
}

button {
    & i {
        display: inline-block;
        min-width: 32px;
    }

    & p {
        display: inline-block;
    }
}

#form-section input {
    border-radius: 0.4em;
}

.heading-small {
    font-weight: 600;
    font-size: 16px;
}

/*
    Styling for all Tables in Account Settings
*/
.table>tbody>tr>td {
    text-align: center;
}

form {
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    border-radius: 10px;
}