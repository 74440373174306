#construction-container {
    position: relative;
    overflow: hidden;
    background-image: url(./Assests/construction.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    min-height: 100vh;

    & #construction-content {
        position: fixed;
        z-index: 10;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: rgba(0, 0, 0, 0.7);
        width: 100%;
        text-align: center;
        padding: 60px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        & #text h1 {
            color: white;
            font-weight: 800 !important;
            letter-spacing: 2px;
            font-size: clamp(24px, 3vw, 64px);
            margin: 0;
        }

        #logo-container {
            width: 150px;

            @media screen and (min-width:720px) {
                width: 200px;
            }
        }
    }
}