#homepage-filter-submit {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    z-index: -2;
}

#homepage-filter-submit.show {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

#homepage-filter-submit:hover {
    border-radius: 0 !important;
    background-color: #e50019 !important;
}

.homepage-hero {

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    position: relative;
    z-index: 1;
}

@media (max-device-width: 1400px) and (orientation: landscape) {
    .homepage-hero {
        margin: 20px 0;
    }
}

@media (min-width: 720px) {
    .homepage-hero {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

@media (min-width: 1200px) {
    .homepage-hero {
        min-height: 0vh;
        max-height: 100%;
    }
}

.homepage-filter-container {
    width: 100%;

    // @media screen and (min-width: 720px) and (max-width:1200px) {
    @media screen and (min-width: 720px) {
        -webkit-box-flex: 2;
        -ms-flex: 2;
        flex: 2;
    }

    // @media screen and (min-width:1200px) {
    //     -webkit-box-flex: 2;
    //     -ms-flex: 2;
    //     flex: 2;
    // }
}

.homepage-hero-container {
    width: 100%;

    @media screen and (min-width: 720px) and (max-width:1200px) {
        -webkit-box-flex: 2;
        -ms-flex: 2;
        flex: 2;
    }

    @media screen and (min-width:1200px) {
        -webkit-box-flex: 2;
        -ms-flex: 2;
        flex: 2;
        padding: 0;
    }
}

.homepage-filter {

    .form-header {
        color: #fff;
        padding-left: 12px;
        font-size: 12px;
        display: none;

        .facetwp-type-search {
            width: 170px;
            margin-bottom: 0;

            .facetwp-btn {
                display: none;
            }
        }

        .partno-search {
            display: inline;
            position: relative;
            margin: 0 12px;

            input {
                outline: 0;
                padding: 6px 24px 6px 12px;
                border-bottom: 1px dotted #fff;
                width: 170px;
            }

            .btn-partno-submit {
                position: absolute;
                top: 0;
                right: 0;
                outline: 0;
                cursor: pointer;
                display: none;

                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        @media screen and (min-width:720px) {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            padding-left: 36px;
            font-size: 14px;
        }

        @media screen and (min-width: 1400px) {
            padding-left: 72px;
            font-size: 16px;

            input::-webkit-input-placeholder {
                color: #d4d4d4;
            }

            input:focus::-webkit-input-placeholder {
                color: #818181;
                -webkit-transition: color 0.3s ease-in-out;
                transition: color 0.3s ease-in-out;
            }

            input:-moz-placeholder {
                color: #d4d4d4;
            }

            input:focus:-moz-placeholder {
                color: #818181;
                -webkit-transition: color 0.3s ease-in-out;
                transition: color 0.3s ease-in-out;
            }

            input::-moz-placeholder {
                color: #d4d4d4;
            }

            input:focus::-moz-placeholder {
                color: #818181;
                -webkit-transition: color 0.3s ease-in-out;
                transition: color 0.3s ease-in-out;
            }

            input:-ms-input-placeholder {
                color: #d4d4d4;
            }

            input:focus:-ms-input-placeholder {
                color: #818181;
                -webkit-transition: color 0.3s ease-in-out;
                transition: color 0.3s ease-in-out;
            }
        }
    }

    .form-body {}

    .filterOptions:hover {
        background-color: #e50019 !important;
        color: #fff !important;
    }
}