.form-container {
    width: 100%;
    position: relative;
    height: unset !important;
    display: grid;

    @media screen and (min-width: 1200px) {
        height: 100vh !important;
    }
}

.form-img {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form-img-2 {
    justify-content: center;
    align-items: center;
    width: 50%;
    height: auto;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    animation: rotation 5s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.form-success {
    text-align: center;
    font-size: 2em;
    margin-top: 80px;
    color: #000;
}

.form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & h1 {
        font-size: clamp(14px, 1.5vw, 22px);
        text-align: start;
        width: 80%;
        margin-bottom: 1rem;
        font-weight: 500;
    }
}

.formSubtext {
    text-align: left !important;
    color: #000;
}

.loginBtn {
    background-color: #e50019;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 1.3em;
    font-weight: 100;
    letter-spacing: 1px;
    border-radius: 0.3em;
    text-transform: none;
    margin: 1em 0 0 0;
}

.forgotPassword {
    font-size: 14px;
    color: #e50019;
    float: right;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}

.form-inputs p {
    font-size: 1.3rem;
    margin-top: 0.5rem;
    color: #e50019;
}

.form-label {
    display: inline-block;
    font-size: 0.8rem;
    margin-bottom: 6px;
    color: #fff;
}

.form-input {
    display: block;
    padding-left: 10px;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 100%;
    border: none;

    &::placeholder {
        color: #595959;
        font-size: 12px;
    }
}

.form-input-btn {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    border-radius: 2px;
    background-color: #e50019;
    outline: none;
    border: none;
    color: #fff;
    font-size: 2rem;

    &:hover {
        cursor: pointer;
        background-color: #e50019;
        transition: all 0.4s ease-out;
    }
}

.form-input-login {
    font-size: 0.8rem;
    margin-top: 10px;
    color: #fff;
    width: 80%;
    text-align: center;
}

.registrationLink a {
    text-decoration: none;
    color: #27cdff;
    font-weight: 600;
}

.formContainer {
    position: relative;
    padding: 5em 0 0 0;
    height: 90vh;
    margin: 3em auto 3em auto;
}

.title {
    font-weight: 500;
    font-size: 2.3em;
}

.headline {
    font-size: 1.3em;
    color: #808080;
}

.loginForm {
    width: unset !important;
    padding: 0;
}

.loginInput {
    /* min-width: 550px; */
    width: 100% !important;
}

.field span {
    float: right;
    color: #e50019;
    text-decoration: underline;
    font-weight: 500;
}

.loginBtn {
    background-color: #e50019;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: clamp(16px, 1.3vw, 18px);
    font-weight: 500 !important;
    letter-spacing: 1px;
    border-radius: 10px;
    text-transform: none;
    // margin: 1em 0 0 0;
    padding: 0.5em 1em;
}

#registrationLink {
    text-align: center;

    & a {
        font-weight: 500;
        color: #e50019 !important;
    }
}