.address-title {
    font-size: clamp(18px, 1.2vw, 24px);
    font-weight: 600;
}

#address-list {
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    border-radius: 10px;
}

.add-address-btn {
    font-size: clamp(14px, 1.8vw, 16px);
    font-weight: 600;
    color: white;
    background-color: #e50019;
    padding: 0.5em 3em;
    // border-radius: 0.4em;
    transition: 0.2s transform ease-in-out;
    text-decoration: none;

    &:hover {
        filter: brightness(110%);
        transform: scale(1.02);
        cursor: pointer;
    }
}