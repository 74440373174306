.css-12k17s7-MuiStepper-root {
    //TODO: Remove this and use the MUI docs to style this section
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    -webkit-align-items: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;

    @media screen and (max-width: 730px) {
        -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
        -webkit-align-items: start !important;
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;

        &>.MuiStep-horizontal {
            margin: 0.2em 0;
        }
    }
}

.delivery-heading {
    font-family: "Montserrat", sans-serif;
    font-size: clamp(20px, 3vw, 24px);
    font-weight: 500;
}

.delivery-option {
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    border-radius: 10px;
    max-width: 700px;

    delivery-option-container &:hover {
        background-color: rgba(200, 200, 200, 0.2);
        box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    }

    &:hover .delivery-option-descriptions {
        color: white;
    }
}

.section-1 {
    flex: 5;
    -webkit-box-flex: 5;
    -moz-box-flex: 5;
    -webkit-flex: 5;
    -ms-flex: 5;
}

.delivery-option-description {
    color: rgb(180, 180, 180);

    @media screen and (min-width: 315px) {
        max-width: 22ch;
    }
}

.section-2 {
    flex: 3;
    -webkit-box-flex: 3;
    -moz-box-flex: 3;
    -webkit-flex: 3;
    -ms-flex: 3;
}

.delivery-option-btn-container {
    @media screen and (min-width: 1399px) {
        border-left: 1px solid rgb(230, 230, 230);
    }
}

.delivery-option-btn {
    padding: 1em 1.2em;
    border-radius: 14px;
    color: white;
    background-color: #e50019;
    transition: 0.2s transform ease-in-out;
    font-weight: 600;
    font-family: 'Montserrat';

    &:hover {
        filter: brightness(110%);
        transform: scale(1.02);
        cursor: pointer;
    }
}

.delivery-option-icon {
    min-width: 60px;
    width: 80px;
}

.collect-content,
.deliver-content {
    span {
        font-weight: 700;
    }

    .delivery-option-description {
        font-weight: 400;
        line-height: 120%;
        display: inline-block;
    }
}