.add-stock-question-icon {
    height: clamp(18px, 1.2vw, 24px);

    &:hover {
        color: #e50019;
        cursor: pointer;
    }
}

.filter-btn {
    color: darkgrey;
    font-size: clamp(17px, 1.1vw, 20px);

    &:hover {
        cursor: pointer;
        color: #e50019;
    }
}

.search-results-container {
    font-size: clamp(20px, 1.4vw, 24px);
    font-weight: 800;
}

.clear-filter-text {
    font-weight: 400;
    color: #e50019;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    &:active {
        color: rgba(229, 0, 25, 0.6);
    }
}

.filter-section {
    background-color: white;
}

.searchFilter {
    text-align: left !important;

    form {
        box-shadow: none;
    }

    .filter-title {
        font-size: clamp(16px, 1.5vw, 18px);
        color: #3a3a3a;
        font-weight: 500;
    }
}

.reset-filters-btn {
    color: black;
    border: 2px solid #e50019;
    border-radius: 10px;
    letter-spacing: 1px;
    font-size: clamp(14px, 1.3vw, 18px);
    padding: 0.5em 2em !important;
    font-weight: 600;
    transition: 0.2s transform ease-in-out;
    min-height: unset;
    line-height: unset;

    &:hover {
        color: #e50019;
        background-color: rgba(229, 0, 25, 0.1);
        text-decoration: underline;
        cursor: pointer;
    }

    &:focus {
        outline: unset !important;
    }
}

/*dropdown search filter */
.searchround {
    background-color: white;
    color: #3a3a3a;
    border: 0;
    border-radius: 2em;
    display: inline-block;
    font-weight: 600;
    font-size: 1em;
    text-transform: initial;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-shadow: 2px 2px 20px #909090;

    background-image: linear-gradient(45deg, transparent 50%, #fff 50%),
        linear-gradient(135deg, #fff 50%, transparent 50%),
        radial-gradient(#e50119 70%, transparent 72%);
    background-position: calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
    background-repeat: no-repeat;

    &::placeholder {
        font-size: 12px;
    }

    &:focus {
        background-image: linear-gradient(45deg, white 50%, transparent 50%),
            linear-gradient(135deg, transparent 50%, white 50%),
            radial-gradient(#e50119 70%, transparent 72%);
        background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
            calc(100% - 0.5em) 0.5em;
        background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
        background-repeat: no-repeat;
        border-color: #e50119;
        outline: 0;
    }

    & &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;

        & & datalist {
            background-color: red;
            color: #000;
            border: 1px solid red !important;
        }
    }
}

.dropdown-container {
    position: relative;

    .close-filter-btn {
        font-weight: 500;
        font-size: clamp(14px, 1.3vw, 18px);
        color: rgb(118, 118, 118);
        position: relative;
        float: right;
        z-index: 100;

        &:hover {
            cursor: pointer;
            color: #e50019
        }

        @media screen and (min-width: 768px) {
            position: absolute;
            right: 2em;
        }
    }
}

.search-products-btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    font-size: clamp(14px, 1.3vw, 18px);
    padding: 0.5em 2em !important;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: #e50019 !important;
    color: #fff;
    text-decoration: none;
    border-radius: 10px;
    transition: 0.3s all ease-in-out;
    min-height: unset;
    line-height: unset;

    &:hover {
        cursor: pointer;
        filter: brightness(110%);
        transform: scale(1.02);
    }

    &:focus {
        outline: unset;
    }
}

.filterOptions:hover {
    background-color: #e50119 !important;
    color: #fff !important;
}

.stockHeader {
    display: grid;
    background-image: url("../../Assests/HomePage/home.png");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(30vh, auto);
}

.search-title {
    font-size: clamp(18px, 1.2vw, 24px);
    font-weight: 600;
}

.add-stock-btn {
    padding: 0.2em 1em;
    border-radius: 0.4em;
    color: white;
    background-color: #e50019;
    transition: 0.2s transform ease-in-out;
    text-decoration: none;
    font-weight: 600;

    &:hover {
        filter: brightness(110%);
        transform: scale(1.02);
        cursor: pointer;
    }

    @media screen and (min-width: 480px) {
        padding: 0.5em 3em;
    }
}

.stockHeading {
    justify-content: space-between;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.searchBar {
    float: right;
}

.react-select {
    border-radius: 1em;
}

.myStockBrand {
    margin: 0 -5em 0 0 0;
}

.hideCheckbox {
    vertical-align: middle;
    position: relative;
    width: 1.5em;
    height: 2em;
    padding: 0;
    margin: 0;
    border: 1px solid red;
}

.tableText {
    position: relative;
}

.showStockInfo {
    margin: 3em 0 0 0;
}

.addStockInput {
    border: 1px solid #ccc;
    width: 30%;
    height: 2.9em;
    padding: 1em;
}

.hideAddedStock {
    border: 1px solid #e70822;
    color: #000;
    font-size: 1.3em;
    float: left;
    height: 2em;
    width: 200px;
    margin-top: 2em;
    border-radius: 0.4em;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    letter-spacing: 1px;

    &:hover {
        background-color: #e70822;
        color: #fff;
    }
}

.tyreDetails {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100% !important;
}

.tyreDetails p {
    font-weight: 600;
}

table {
    thead {
        border-bottom: 1px solid rgba(10, 10, 10, 0.1);

        tr th {
            text-align: left !important;
            font-size: clamp(12px, 1.2vw, 16px);
        }
    }

    tbody {
        font-size: clamp(12px, 1vw, 16px);

        tr {
            background-color: rgba(0, 0, 0, 0.05);

            &:hover {
                background-color: rgba(0, 0, 0, 0.02);
                box-shadow: 0px 0px 19px -10px #111;
            }

            &:nth-of-type(2n) {
                background-color: rgba(0, 0, 0, 0.1);

                &:hover {
                    background-color: rgba(0, 0, 0, 0.01);
                }
            }

            td {
                text-align: left !important;
            }

            @media screen and (max-width: 480px) {
                padding: 1em;
            }

            @media screen and (min-width: 481px) and (max-width: 720px) {
                padding: 2em;
            }

            @media screen and (max-width: 720px) {
                td {
                    text-align: right !important;
                }

                &:nth-of-type(2n) td {
                    text-align: left !important;
                }
            }

            td {
                margin-right: 1em;
            }
        }

    }
}

thead tr,
tr:nth-child(2n) {
    background-color: rgba(0, 0, 0, 0.04);
}

td {
    // padding: 1em 0.5em !important;
}


form {
    width: 100% !important;
}

@media screen and (max-width: 768px) {
    .viewStockContainer {
        display: block !important;
    }

    .addStockContainer {
        display: block !important;
    }
}

#add-img-label {
    display: block;
}

#add-single-img {
    display: none;
}

.uploadIcon {
    display: block;
    margin: 0.5em auto 0 auto;
}

.custom__form input {
    opacity: 0;
    height: 0;
}

.custom__image-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    & label {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 150%;
        cursor: pointer;
        width: 100px;
        height: 100px;
        background-color: #e6e6e6;
        border-radius: 5px;
        object-fit: cover;
    }

    & img {
        width: auto;
        height: 90px;
        border-radius: 5px;
        object-fit: cover;

        @media screen and (min-width: 480px) {
            width: auto;
            height: 50px;
        }

        @media screen and (min-width: 720px) {
            width: auto;
            height: 80px;
        }
    }
}

.uploadText {
    font-size: clamp(14px, 1.25vw, 16px);
}

.column-content {
    text-align: left;
}

/*
    Active add stock component
*/
#create-stock-item {
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    border-radius: 10px;
}

.active-product-name {
    font-size: clamp(26px, 1.5vw, 36px);
}

.reset-active-product {
    border: 2px solid #e50019;
    border-radius: 10px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    padding: 0.7em 1.2em;
    color: #e50019;

    &:hover {
        background-color: rgba(229, 0, 25, 0.1);
        text-decoration: underline;
    }
}

.decline-message {
    min-width: 400px;

    @media screen and (max-width: 768px) {
        min-width: 100px;
    }
}

.my-stock-action-btn {
    color: white;
    border-radius: 10px;

    &:hover,
    &:focus {
        background-color: rgba(229, 0, 25, 0.1);
        cursor: pointer;
        transform: scale(1.1);
        filter: brightness(110%);
    }
}

.my-stock-icons {
    fill: #e50019;
    color: #e50019;
    padding: 10px;
    justify-content: center;
    align-items: center;
    height: 16px !important;
}