.navbar-primary-container {
    overflow: hidden;
}

.main-navigation {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    padding-bottom: 15px;
    z-index: -11;
}

#cart-link,
#account-link,
#profile-link,
#logout-link {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
}

#cart-link span {
    position: absolute;
    top: -5px;
    right: -11px;
    background-color: #d91522;
    border-radius: 100%;
    padding: 0px 6px;
    font-size: 10px;
}

.cart-count {
    color: white;
}

.nav-container-menu .navbar-secondary a:hover span {
    color: white;
}

.account-dropdown-container {
    position: relative;


    .account-dropdown {
        position: absolute;
        visibility: visible;

        top: 100%;
        display: none;
        z-index: 100;
        background-color: black;
        padding: 1em 0.5em;

        .is-active-subnav {
            width: 100%;
            background-color: #e50019;
        }

        @media screen and (min-width: 768px) {
            visibility: hidden;
        }
    }

    &:hover .account-dropdown {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}

.is-active-nav-item {
    color: #e50019 !important;
    fill: #e50019 !important;

    & svg>g>path {
        color: transparent !important;
        fill: #e50019;
    }
}

.menuLink:focus {
    color: e50019 !important;
}

.menu-item .menu-item-login-register:hover {
    color: #e50019 !important;
    cursor: pointer;
}

svg>g>path {
    color: transparent !important;
    fill: white;
}

#cart-link:hover>svg>g>path {
    fill: #e50019;
    color: #e50019;
}

@media screen and (max-width: 480px) {
    .menu-item {
        font-size: 0.1em !important;
    }

    #mainLogo {
        max-width: 100px important;
    }
}

@media screen and (max-width: 768px) {

    /* hide primary nav */
    .desktop-nav {
        display: none !important;
    }
}

@media screen and (min-width: 768px) {

    /* show primary nav */
    .desktop-nav {
        display: block !important;
    }
}

@media (max-width: 760px) {
    .secondary-navigation {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        -webkit-transform: translate(-100vw, 0);
        -ms-transform: translate(-100vw, 0);
        transform: translate(-100vw, 0);
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        z-index: -11;
        min-height: 100vh;
    }

    .secondary-navigation.open {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

.nav-tabs {

    & .active a,
    & .active a::before {
        background-color: #3a3a3a;
        border-color: #3a3a3a;
        color: #fff;
    }

    & a {
        color: #3a3a3a;
        background-color: #fff;
        white-space: nowrap;
        display: block;
        height: 36px;
        line-height: 36px;

        &:hover,
        &:hover::before {
            border-color: #000;
            background-color: #000;
            color: #fff;

        }
    }

    & {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        background-color: #fff;
        margin-bottom: 24px;
    }

    &>li {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: center;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }

    &>li:not(:first-child) {
        margin-left: -1px;
    }

    &>li a {
        border-radius: 0;
        margin: inherit;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 14px;
    }

    &>li a::after,
    &>li a::before {
        display: block;
    }

    &>li a.bordered-skew-left {
        border-left: 0;
    }

    &>li a.bordered-skew-left::after {
        border: 0;
    }

    &>li a.bordered-skew-right {
        border-right: 0;
    }

    &>li a.bordered-skew-right::after {
        border: 0;
    }

    &>li a.bordered-skew-both {
        border-left: 0;
        border-right: 0;
    }

    &>li a.bordered-skew-both::after {
        border-left: 0;
        border-right: 2px solid;
    }

    &>li:first-child a {
        position: relative;
        border-width: 2px;
        border-style: solid;
    }

    &>li:first-child a::before {
        content: "";
        height: 100%;
        position: absolute;
        background-color: inherit;
        width: 12px;
    }

    &>li:first-child a::after {
        content: "";
        height: 100%;
        position: absolute;
        background-color: inherit;
        width: 0;
        top: 0;
        left: 0;
    }

    &>li:first-child a::before {
        border-left: 0;
        top: 0;
        right: -6px;
        left: auto;
        -webkit-transform: skewX(-10deg);
        -ms-transform: skewX(-10deg);
        transform: skewX(-10deg);
    }

    &>li:first-child a::after,
    &>li:first-child a::before {
        top: -2px;
        height: -webkit-calc(100% + 2px * 2);
        height: calc(100% + 2px * 2);
        border-width: 2px;
    }

    &>li:last-child a {
        position: relative;
        border-width: 2px;
        border-style: solid;
    }

    &>li:last-child a::before {
        content: "";
        height: 100%;
        position: absolute;
        background-color: inherit;
        width: 12px;
    }

    &>li:last-child a::after {
        content: "";
        height: 100%;
        position: absolute;
        background-color: inherit;
        width: 0;
        top: 0;
        right: 0;
    }

    &>li:last-child a::before {
        border-right: 0;
        top: 0;
        left: -6px;
        -webkit-transform: skewX(-10deg);
        -ms-transform: skewX(-10deg);
        transform: skewX(-10deg);

    }

    &>li:last-child a::after,
    &>li:last-child a::before {
        top: -2px;
        height: -webkit-calc(100% + 2px * 2);
        height: calc(100% + 2px * 2);
        border-width: 2px;

    }

    @media (min-width: 720px) {

        & a {
            height: 50px;
            line-height: 50px;
        }
    }

    @media (max-width: 1199px) {
        &>li:first-child a {
            border-radius: 2px;
        }

        &>li:first-child a::after,
        &>li:first-child a::before {
            display: none;
        }

        &>li:last-child a {
            border-radius: 2px;
        }

        &>li:last-child a::after,
        &>li:last-child a::before {
            display: none;
        }
    }


    @media (min-width: 1200px) {

        &>li:first-child a {
            border-radius: 0;
        }

        &>li:first-child a::after,
        &>li:first-child a::before {
            border-radius: 0;
        }

        &>li:first-child a {
            border-right: 0;
            margin-left: 0;
            margin-right: 6px;
            border-right: 0;
        }

        &>li:first-child a::after {
            border-left: 0;
            border-right: 0;
        }

        &>li:last-child a {
            border-radius: 0;
        }

        &>li:last-child a::after,
        &>li:last-child a::before {
            border-radius: 0;
        }

        &>li:last-child a {
            border-left: 0;
        }

        &>li:last-child a::after {
            border-left: 0;
            border-right: 0;
        }

        &>li:last-child a {
            border-left: 0;
            margin-left: 6px;
            margin-right: 0;
        }
    }
}

.nav {
    list-style: none;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    .bordered-skew-left,
    .bordered-skew-right {
        border-radius: 0;
        margin: inherit;
    }

    .bordered-skew-left::after,
    .bordered-skew-left::before,
    .bordered-skew-right::after,
    .bordered-skew-right::before {
        display: block;
    }

    .bordered-skew-left.bordered-skew-left,
    .bordered-skew-right.bordered-skew-left {
        border-left: 0;
    }

    .bordered-skew-left.bordered-skew-left::after,
    .bordered-skew-right.bordered-skew-left::after {
        border: 0;
    }

    .bordered-skew-left.bordered-skew-right,
    .bordered-skew-right.bordered-skew-right {
        border-right: 0;
    }

    .bordered-skew-left.bordered-skew-right::after,
    .bordered-skew-right.bordered-skew-right::after {
        border: 0;
    }

    .bordered-skew-left.bordered-skew-both,
    .bordered-skew-right.bordered-skew-both {
        border-left: 0;
        border-right: 0;
    }

    .bordered-skew-left.bordered-skew-both::after,
    .bordered-skew-right.bordered-skew-both::after {
        border-left: 0;
        border-right: 2px solid;
    }

    .bordered-skew-left::before,
    .bordered-skew-right::before {
        border-left: 0;
        border-right: 0;
    }
}

.nav-toggle {
    position: relative;
    margin: 0;
    padding: 0;
    width: 24px;
    height: 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    -webkit-transition: background 0.1s;
    transition: background 0.1s;
}

.nav-toggle:focus {
    outline: 0;
}

.nav-toggle span {
    display: block;
    position: absolute;
    top: 13.5px;
    left: 0;
    right: 0;
    height: 3px;
    background: #fff;
}

.nav-toggle span::after,
.nav-toggle span::before {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #fff;
    content: "";
}

.nav-toggle span::before {
    top: -8px;
}

.nav-toggle span::after {
    bottom: -8px;
}

.nav-toggle--htx span {
    -webkit-transition: background 0s 0.1s;
    transition: background 0s 0.1s;
}

.nav-toggle--htx span::before {
    -webkit-transition: top 0.1s linear 0.1s, -webkit-transform 0.1s linear 0s;
    transition: top 0.1s linear 0.1s, -webkit-transform 0.1s linear 0s;
    transition: top 0.1s linear 0.1s, transform 0.1s linear 0s;
    transition: top 0.1s linear 0.1s, transform 0.1s linear 0s,
        -webkit-transform 0.1s linear 0s;
}

.nav-toggle--htx span::after {
    -webkit-transition: bottom 0.1s linear 0.1s,
        -webkit-transform 0.1s linear 0s;
    transition: bottom 0.1s linear 0.1s, -webkit-transform 0.1s linear 0s;
    transition: bottom 0.1s linear 0.1s, transform 0.1s linear 0s;
    transition: bottom 0.1s linear 0.1s, transform 0.1s linear 0s,
        -webkit-transform 0.1s linear 0s;
}

.nav-toggle--htx.is-active span {
    background: 0 0;
}

.nav-toggle--htx.is-active span::before {
    top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.nav-toggle--htx.is-active span::after {
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.nav-toggle--htx.is-active span::before {
    -webkit-transition: top 0.1s linear 0s, -webkit-transform 0.1s linear 0.1s;
    transition: top 0.1s linear 0s, -webkit-transform 0.1s linear 0.1s;
    transition: top 0.1s linear 0s, transform 0.1s linear 0.1s;
    transition: top 0.1s linear 0s, transform 0.1s linear 0.1s,
        -webkit-transform 0.1s linear 0.1s;
}

.nav-toggle--htx.is-active span::after {
    -webkit-transition: bottom 0.1s linear 0s,
        -webkit-transform 0.1s linear 0.1s;
    transition: bottom 0.1s linear 0s, -webkit-transform 0.1s linear 0.1s;
    transition: bottom 0.1s linear 0s, transform 0.1s linear 0.1s;
    transition: bottom 0.1s linear 0s, transform 0.1s linear 0.1s,
        -webkit-transform 0.1s linear 0.1s;
}

.has-bg .nav-container-menu .navbar-secondary {
    background-color: transparent;
}

.has-bg .navbar-search-desktop {
    opacity: 0.5;
}

.navbar-primary>.menu-item .menuLink:hover {
    color: #d91522 !important;
    font-weight: 600;
}

.navbar-primary>.menu-item .menuLink:active {
    color: #d91522 !important;
    font-weight: 600;
}

.navbar-primary>.menu-item>a,
.navbar-primary>.menu-item>span {
    display: block;
    color: #fff;
    padding: 12px 2vw;
    font-size: clamp(10px, 1.5vw, 22px);
}

.nav-container-branding {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    min-height: 54px;
}

@media (min-width: 1200px) {
    .nav-container-branding {
        width: 20%;
    }
}

.nav-container-menu {
    width: 100% !important;
}

.nav-container-menu .navbar-secondary {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    // -ms-flex-direction: column;
    // flex-direction: column;
}

.nav-container-menu .navbar-secondary a {
    font-weight: 400;
}

.nav-container-menu .navbar-secondary a:hover {
    color: #d91522;
}

@media (min-width: 1200px) {
    .nav-container-menu .navbar-secondary {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media (min-width: 1400px) {
    .nav-container-menu .navbar-secondary {
        padding-right: 3vw;
        margin: 8px 0px 8px 0px;
    }
}

@media (min-width: 1400px) {
    .nav-container-menu .navbar-primary {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin-left: auto;
        padding-right: 2vw;
    }
}

.navbar-primary-container {
    width: 100%;
    background-color: #000;
    opacity: 0.7;
    z-index: 2;
}

.navbar-primary-container .navbar-primary-wrap {
    float: right;
}

@media (min-width: 1200px) {
    .navbar-primary-container .navbar-primary-wrap {
        -webkit-transform: skewX(-10deg);
        -ms-transform: skewX(-10deg);
        transform: skewX(-10deg);
        -webkit-transition: -webkit-transform 0.1s ease-in-out;
        transition: -webkit-transform 0.1s ease-in-out;
        transition: transform 0.1s ease-in-out;
        transition: transform 0.1s ease-in-out,
            -webkit-transform 0.1s ease-in-out;
        float: right;
    }
}

.navbar-primary {
    max-width: 1050px;
}

.navbar-primary>.menu-item {
    text-align: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-property: background-color;
    transition-property: background-color;
}

.navbar-primary>.menu-item svg {
    margin-left: 6px;
    width: 12px;
    height: 12px;
}

.navbar-primary>.menu-item.cart svg {
    margin-left: 0;
    margin-right: 6px;
    width: 24px;
    height: 24px;
}

.navbar-primary>.menu-item.cart a {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
}

.navbar-primary>.menu-item.cart .cart-empty {
    display: block;
}

.navbar-primary>.menu-item.cart .cart-not-empty {
    display: none;
}

.navbar-primary>.menu-item.cart-has-items .cart-empty {
    display: none;
}

.navbar-primary>.menu-item.cart-has-items .cart-not-empty {
    display: block;
}

.navbar-primary>.menu-item>a {
    font-weight: 500;
    text-transform: uppercase;
}

@media (min-width: 1200px) {
    .navbar-primary>.menu-item {
        text-transform: uppercase;
    }
}

.navbar-primary>.menu-item.active,
.navbar-primary>.menu-item:hover {
    background-color: white;
}

.navbar-primary>.menu-item:first-child {
    border-right: 1px solid #3a3a3a;
}

@media (min-width: 1200px) {
    .navbar-primary>.menu-item:last-child a {
        position: relative;
    }
}

@media (min-width: 1400px) {
    .navbar-primary>.menu-item:last-child a::after {
        right: -3vw;
    }
}

@media (min-width: 720px) {
    .navbar-primary>.menu-item:first-child {
        border-right: 0;
    }
}

@media (min-width: 1400px) {
    .navbar-primary>.menu-item {
        -webkit-box-flex: initial;
        -ms-flex: initial;
        flex: initial;
    }
}

@media (min-width: 1200px) {

    .navbar-primary>.menu-item>a,
    .navbar-primary>.menu-item>span {
        font-size: 16px;
        padding: 16px 2vw;
        white-space: nowrap;
        -webkit-transform: skewX(10.01deg);
        -ms-transform: skewX(10.01deg);
        transform: skewX(10.01deg);
    }

    .navbar-primary>.menu-item>a:hover,
    .navbar-primary>.menu-item>span:hover {
        color: #000;
    }
}

.navbar-search-desktop {
    display: none;
}

.navbar-search-desktop .search-field::-moz-placeholder {
    color: #818181;
    opacity: 1;
}

.navbar-search-desktop .search-field:-ms-input-placeholder {
    color: #818181;
}

.navbar-search-desktop .search-field::-webkit-input-placeholder {
    color: #818181;
}

@media (min-width: 1200px) {
    .navbar-search-desktop {
        display: block;
        min-width: 300px;
        width: 20%;
    }
}

@media (min-width: 1400px) {
    .navbar-search-desktop {
        min-width: 350px;
    }
}

.navbar-search-desktop .form-navbar-search {
    height: 58px;
}

.navbar-secondary>li {
    padding: 12px 24px;
    font-size: 14px;
}

.navbar-secondary>li.hide-desktop {
    display: block;
    text-transform: uppercase;
}

@media (min-width: 1200px) {
    .navbar-secondary>li.hide-desktop {
        display: none;
    }
}

.navbar-secondary>li a {
    font-size: 20px;
    color: #fff;
    padding: 0 0 0 0.7em;
}

.navbar-secondary>li a:hover {
    color: gray;
}

@media (min-width: 1200px) {
    .navbar-secondary>li {
        padding: 6px 1vw;
        border-bottom: 0;
    }
}

.navbar-search-mobile {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
}

.navbar-search-mobile form {
    width: 100%;
}

.navbar-search-mobile form .search-input {
    min-width: 60px;
    width: 100%;
}

.navbar-search-mobile .menu-hamburger {
    width: 60px;
    background-color: #000;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 12;
}

@media (min-width: 1200px) {
    .navbar-search-mobile {
        display: none;
    }
}