.my-stock-action-btn {
    color: white;
    border-radius: 10px;

    &:hover,
    &:focus {
        background-color: rgba(229, 0, 25, 0.1);
        cursor: pointer;
        transform: scale(1.1);
        filter: brightness(110%);
    }
}

.my-stock-icons {
    fill: #e50019;
    color: #e50019;
    padding: 10px;
    justify-content: center;
    align-items: center;
    height: 16px !important;
}