.main-completed-order-container {
    width: 90%;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    border-radius: 10px;

    &>* {
        margin-bottom: 1rem;
    }

    .main-complete-icon {
        width: 100px;
    }

    .order-complete-heading {
        color: #e50019;
        font-weight: 500;
    }

    .order-complete-paragraph {
        font-weight: 500;
    }

    .order-complete-btn {
        font-weight: 700;
        text-decoration: none;
    }
}