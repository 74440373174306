.text-error {
    color: #f00e0e;
    font-size: 0.8em;
    font-weight: 600;
    text-align: left;
}

#addressForm {
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%) !important;
    border-radius: 10px;
}

#submit-address,
#cancel-edit-address-btn {
    line-height: unset;
}

#submit-address {
    display: block;
    color: white;
    background-color: #e50019;
    font-weight: 600;
    text-align: center;
    padding: 0.5em 3em;
    border-radius: 0.4em;
    font-family: "Montserrat", sans-serif;
    transition: 0.2s transform ease-in-out;
    width: 100% !important;

    &:hover {
        filter: brightness(110%);
        transform: scale(1.02);
        cursor: pointer;
    }

    @media screen and (min-width:360px) {
        width: unset !important;
    }
}

#cancel-edit-address-btn {
    color: #e50019;
    padding: 0.5em 3em;
    border-radius: 10px;
    width: 100% !important;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    @media screen and (min-width:360px) {
        width: unset !important;
    }
}

#bIsDefault {
    width: 20px;
    height: 20px;
}

.form-container {
    margin: 100px auto;
    width: 100%;
    position: relative;
}

.address-form-success {
    text-align: center;
    font-size: 24px;
    margin-top: 80px;
    color: #000;
}

.form-inputs {
    margin-bottom: 0.5rem;
    /* width: 80%; */
}

.text-errors {
    font-size: 1.3rem;
    margin-top: 0.5rem;
    color: #f00e0e !important;
}

.form-label {
    display: inline-block;
    font-size: 0.8rem;
    margin-bottom: 6px;
    color: #fff;
}

.form-input {
    display: block;
    padding-left: 10px;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 100%;
    border: none;

    &::placeholder {
        color: #595959;
        font-size: 12px;
    }
}