.reseller-page-content {
    padding: 0em;
    width: 100%;

    h4 {
        font-weight: 800;
    }

    p {
        margin: 0.5em 0 0em 0;
        font-size: 17px;
    }

    h2 {
        color: #d91522;
        font-weight: 600;
    }

    // h2 {
    //     text-align: center;
    // }

    @media screen and (min-width: 720px) {
        padding: 1em 5em !important;
    }
}

.reseller-page-header {
    display: grid;
    // background-image: url("../Assests/HomePage/undergroundtruck.png");
    background-image: url("../../Assests/HomePage/reseller-background-image-1.png"),
        linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    background-blend-mode: overlay;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(140px, auto);
}

.reseller-pre-container {
    background-color: #d91522;

    #reseller-pre-content {
        letter-spacing: 1px;
        padding: 2em 1.5em;
        color: white;

        h2 {
            color: #fff;
            font-size: clamp(1.3em, 7vw, 2.3em);
            font-weight: 500;
            line-height: 1em;
        }

        p {
            max-width: 75ch;
            font-size: clamp(16px, 1.4vw, 20px);
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 132%;
        }

        span {
            max-width: 75ch;
            line-height: 130%;
        }

        @media screen and (min-width: 720px) {
            padding: 3em 5em;
        }
    }
}

.resellerHeader {
    margin-bottom: 10px;
    padding: 0 1.5em !important;
}

.reseller {
    text-align: left !important;
    max-width: 75ch;
}

.vision-container,
.quality-container {
    max-width: 45ch;
    border-radius: 20px;

    @media screen and (min-width:480px) {}

    @media screen and (min-width: 720px) {
        margin: 1em 2em;
        border: 1px solid darkgrey;
    }
}

.header {
    display: grid;
    background-color: #d91522;
    background-attachment: fixed;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(700px, auto);
}

.contact-us-options {
    padding: 0.5em 2em;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        padding: 0 1em;
        font-size: 19px;
        font-weight: 500;
    }

    & button {
        background-color: #cfcfcf;
        border-radius: 10px;
        text-align: center;
        padding: 0.8em 1.5em;
        font-size: 18px;
        font-weight: 600;
        transition: 0.2s all ease-in-out;
        cursor: pointer;

        &:hover {
            cursor: pointer;
            filter: brightness(110%);
            transform: scale(1.02);
        }

        &:focus {
            outline: none;
            border: 0;
        }

        @media screen and (min-width: 769px) and (max-width: 1024px) {
            width: 30%;
        }

        @media screen and (min-width: 1520px) {
            width: 10%;
        }
    }

    @media screen and (max-width: 480px) {
        display: block;
        padding: 0em 2em;

        p {
            display: none;
        }

        button {
            text-align: center;
            width: 100%;
            margin-top: 0.5em;
        }
    }
}

.contact-us-header {
    text-align: center;
}

.list {
    margin-left: -16px;
    font-weight: bold;

    @media screen and (min-width: 720px) {
        margin-left: 0px;
    }
}

.indented-list {
    margin-bottom: 10px;
    margin-left: 16px;
    list-style-type: '- ';
    font-weight: normal !important;
}

@media screen and (max-width: 480px) {
    .contact-us {
        margin: 2em 0em;
    }

    .contact-us-header {
        font-size: 18px;
    }
}

.reseller-text {
    @media screen and (max-width: 767px) {
        font-weight: 500 !important;
    }
}