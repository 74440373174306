.pdf-list-container {
    box-sizing: border-box;
    flex: 50% 1;
    flex-wrap: wrap;
    // background-color: rgba(229, 0, 25, 0.1);
    border: rgba(229, 0, 25, 0.1);
    border-radius: 10px;
    padding: 2rem 1rem;
    margin: 0rem 2rem;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover {
        .brochure-download-btn {
            color: #e50019;
            text-decoration: underline;
        }
    }

    &:focus {
        .brochure-download-btn {
            outline: none;
        }
    }

    .image-container {
        width: 5rem !important;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;

        .pdf-image {
            aspect-ratio: 407/512 !important;
            margin-bottom: 1rem;
        }
    }

    .brochure-download-btn {
        color: #e50019;
        letter-spacing: 1px;
        font-size: clamp(15px, 1.2vw, 18px);
        font-weight: 500;
        cursor: pointer;
    }
}