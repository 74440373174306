.ForgotPasswordContainer {
    background-image: url('../../../Assests/HomePage/resetPageBg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
}

.homeLogo {
    &:hover {
        cursor: pointer;
    }
}

.ForgotPasswordNav {
    padding: 2em;

    &:hover {
        cursor: pointer;
    }
}

.pageContainer {
    width: 100%;
    text-align: center;
}

.formContainer {
    width: 35%;
    display: inline-block;
    margin: 0 auto;
    padding: 2em;
}

.formTitle {
    font-size: .9em;
    font-weight: 500;
    text-align: left;
}

.formLabel {
    text-align: left;
    color: #000 !important;
    font-size: 1.2em !important;
}

#forgotPasswordInput {
    background-color: #fff;
    background-image: none;
    border: 1px solid #d4d4d4;
    border-radius: 10px;
    box-shadow: inset 0 0 3px 0 rgb(0 0 0 / 10%);
    color: #000;
    display: block;
    font-size: 14px;
    height: 36px;
    padding: 10px;
    transition-duration: .2s;
    transition-property: border-color, box-shadow;
    transition-timing-function: ease-in-out;
    width: 100%;

    &:hover {
        border-color: #e50019;
    }

    &::placeholder {
        font-style: normal !important;
    }
}

.text-errors {
    text-align: left;
    font-size: 1em;
    font-weight: 500;
}

#forgotPasswordbtn {
    background-color: #d91522;
    transition: background-color 0.2s all ease-in-out;
    border-radius: .4em;
    color: #fff;
    font-size: clamp(1em, 1.25vw, 3em);
    padding: .5em;
    width: 100%;
    cursor: pointer;

    &:hover {
        background-color: #ce0b0b;
    }
}

#registrationLink {
    & a {
        text-decoration: none;
        color: #000;
    }

    & a:hover {
        text-decoration: underline;
        color: #ce0b0b;
        font-weight: 600;
    }
}

@media screen and (min-width: 80px) and (max-width: 1024px) {
    .formContainer {
        width: 100%;
        display: inline-block;
        margin: 0 auto;
        padding: 2em;
    }

    #forgotPasswordbtn {
        height: 40px;
    }
}

.forgot-container {
    width: 80%;
    margin: 0 auto;

    @media screen and (min-width: 450px) {

        width: 50%;
    }


    @media screen and (min-width: 768px) {
        width: 35%;

    }
}