.swiper-main-container {
    width: 98vw;
    height: 180px;
    --swiper-navigation-size: 30px;

    @media screen and (min-width: 720px) {
        height: 200px;
        --swiper-navigation-size: 40px;
    }

    @media screen and (max-width:820px) {
        width: 90vw;
    }

    .swiper-slide-brands {
        height: 200px;

        @media screen and (min-width: 720px) {
            height: 200px;
        }

        .slide {

            a {
                margin: 0 auto;

                .image-slide {
                    // width: 200px !important;
                    max-height: 90px;
                }
            }
        }
    }
}

.slide {
    height: 150px;
    width: 250px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    // padding: 15px;
    perspective: 100px;
    cursor: pointer;

    @media screen and (min-width: 481px) {
        // height: 200px;
    }
}

.swiper-button-prev,
.swiper-button-next {
    transform: translateY(-50%);
}

.image-slide {
    width: 100% !important;
    height: 100% !important;
    transition: transform 2s;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);

    &:hover {
        transform: translateZ(10px);
        -webkit-filter: grayscale(0);
        filter: grayscale(0);
    }
}

@media screen and (max-width: 480px) {
    .item-3 {
        display: block;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: minmax(10px, auto);
    }
}