.form-container {
    width: 100%;
    position: relative;
    height: 100vh;
    display: grid;
}

.form-img {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form-img-2 {
    justify-content: center;
    align-items: center;
    width: 50%;
    height: auto;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    animation: rotation 5s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.form-success {
    text-align: center;
    font-size: 2em;
    margin-top: 80px;
    color: #000;
}

.form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & h1 {
        font-size: clamp(14px, 1.5vw, 22px);
        text-align: start;
        width: 80%;
        margin-bottom: 1rem;
        font-weight: 500;
    }
}

#reseller-add-stock-form {
    box-shadow: none;
}

.formSubtext {
    text-align: left !important;
    color: #000;
}

.forgotPassword {
    font-size: 14px;
    color: #e50019;
    float: right;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.form-inputs p {
    font-size: 1.3rem;
    margin-top: 0.5rem;
    color: #e50019;
}

.form-label {
    display: inline-block;
    font-size: 0.8rem;
    margin-bottom: 6px;
    color: #fff;
}

.form-input {
    display: block;
    padding-left: 10px;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 100%;
    border: none;

    &::placeholder {
        color: #595959;
        font-size: 12px;
    }
}

.form-input-btn {
    width: 100%;
    // height: 50px;
    margin-top: 10px;
    // border-radius: 2px;
    background-color: #e50019;
    outline: none;
    border: none;
    color: #fff;
    // font-size: 2rem;

    border-radius: 10px;
    letter-spacing: 1px;
    font-size: clamp(16px, 2.5vw, 18px) !important;
    padding: 0.5em 1em;
    font-weight: 600;
    cursor: pointer;

    &:hover {
        cursor: pointer;
        background-color: #e50019;
        transition: all 0.4s ease-out;
    }
}

.form-input-login {
    font-size: 0.8rem;
    margin-top: 10px;
    color: #fff;
    width: 80%;
    text-align: center;
}

.registrationLink a {
    text-decoration: none;
    color: #27cdff;
    font-weight: 600;
}

.formContainer {
    position: relative;
    padding: 5em 0 0 0;
    height: 90vh;
    margin: 3em auto 3em auto;
}

.title {
    font-weight: 500;
    font-size: 2.3em;
}

.headline {
    font-size: 1.3em;
    color: #808080;
}

.loginForm {
    width: unset !important;
    padding: 0;
}

.loginInput {
    /* min-width: 550px; */
    width: 100% !important;
}

.field span {
    float: right;
    color: #e50019;
    text-decoration: underline;
    font-weight: 500;
}

#registrationLink {
    text-align: center;
}

#registrationLink a {
    font-weight: 600;
    color: #e50019 !important;
}

.upload-img-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    & #add-img-label {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 150%;
        text-align: center;
        cursor: pointer;
        width: 300px;
        height: 300px;
        background-color: #e6e6e6;
        border-radius: 5px;
        object-fit: cover;
    }

    & img {
        width: auto;
        height: 250px;
        // border-radius: 5px;
        object-fit: fit;
    }
}

.secondary-outline-btn {
    width: unset !important;
}