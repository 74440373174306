.registerContainer {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    min-height: 100vh;
}

.register-page-header {
    display: grid;
    background-color: red;
    background-position: right top;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(30vh, auto);
}

@media (max-width: 1200px) {
    .LoginCover {
        display: none;
    }

    .register-page-header {
        display: grid;
        background-image: url("../../Assests/HomePage/opencastmine.png");
        background-position: right top;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        grid-template-columns: 1fr;
        grid-auto-rows: minmax(100vh, auto);
    }
}