.payment-heading {
    font-family: "Montserrat", sans-serif;
    font-size: clamp(20px, 3vw, 24px);
    font-weight: 500;
}

#payment-option-list {
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    border-radius: 10px;
}

.payment-option-item {
    /* filter: ; */
    border: 1px solid rgba(200, 200, 200, 0.5);
    border-radius: 5px;

    &:hover {
        background-color: rgba(200, 200, 200, 0.5);
        cursor: pointer;
    }
}

.payment-list {
    display: none;

    @media screen and (min-width: 320px) {
        display: block;
    }
}

.payment-list-mobile {
    display: block;

    @media screen and (min-width: 320px) {
        display: none;
    }
}

#payment-list {
    max-width: 640px !important;
    margin: 0 auto;
}