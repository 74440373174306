.add-stock-instructions-container {
    h3 {
        font-size: clamp(16px, 1.2vw, 20px);
    }

    p {
        font-size: clamp(14px, 1.2vw, 18px);
        max-width: 70ch;
    }
}

.click-here {
    color: #e50019;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}