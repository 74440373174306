.page-content {
    padding: 0em 3em 2em 3em;
    width: 100%;

    @media screen and (min-width: 720px) {
        padding: 2em 5em 2em 5em;
    }
}

.page-content h4 {
    font-weight: 800;
}

.page-content p {
    margin: 0.5em 0 0em 0;
    font-size: 17px;
}

.page-content h2 {
    color: #d91522;
    font-weight: 600;
}

.page-content h3 {
    text-align: center;
}

.about-page-header {
    display: grid;
    // background-image: url("../../Assests/HomePage/undergroundtruck (cut).jpg");
    background-image: url("../../Assests/HomePage/reseller-background-image-1.png"),
        linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    background-blend-mode: overlay;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(140px, auto);
}

.about-us-pre-container {
    background-color: #d91522;

    #about-us-pre-content {
        letter-spacing: 1px;
        padding: 2em 2em;
        color: white;

        p {
            max-width: 75ch;
            font-size: clamp(16px, 1.4vw, 20px);
            font-weight: 600;
            letter-spacing: 1px;
            line-height: 130%;
        }

        span {
            max-width: 75ch;
            line-height: 130%;
        }

        @media screen and (min-width: 600px) {
            padding: 3em 5em;
        }
    }
}

.aboutUs {
    text-align: left !important;
}

span.aboutUs {
    max-width: 75ch;
}

.vision-container,
.quality-container {
    max-width: 45ch;
    border-radius: 20px;

    @media screen and (min-width:480px) {}

    @media screen and (min-width: 720px) {
        margin: 1em 2em;
        border: 1px solid darkgrey;
    }
}

.header {
    display: grid;
    background-color: #d91522;
    background-attachment: fixed;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(700px, auto);
}

.contact-us-options {
    padding: 1em 2em;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        padding: 0 1em 0 1em;
        font-size: 19px;
        font-weight: 500;
    }

    & button {
        background-color: #cfcfcf;
        border-radius: 10px;
        text-align: center;
        padding: 0.8em 1.5em;
        font-size: 18px;
        font-weight: 600;
        transition: 0.2s all ease-in-out;
        cursor: pointer;

        &:hover {
            cursor: pointer;
            filter: brightness(110%);
            transform: scale(1.02);
        }

        &:focus {
            outline: none;
            border: 0;
        }
    }
}

.contact-us-header {
    text-align: center;
}

@media screen and (max-width: 480px) {
    .contact-us {
        margin: 2em 0em;
    }

    .contact-us-header {
        font-size: 18px;
    }

    .contact-us-options {
        display: block;
        padding: 0em 2em;

        p {
            display: none;
        }

        button {
            text-align: center;
            width: 100%;
            margin-top: 0.5em;
        }
    }

    .page-content {
        padding: 0em 2em 0em 2em !important;
        width: 100%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .contact-us-options button {
        width: 30%;
    }
}

@media screen and (min-width: 1520px) {

    .contact-us-options button {
        width: 10%;
    }
}