#sold-stock-title {
    font-size: clamp(18px, 5vw, 24px);
    font-weight: 600;
}

#row-total {
    background-color: darkgray;
    color: white;
    font-weight: 600;

    #total-text {
        text-align: right !important;
    }
}