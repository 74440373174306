.checkout-content {

    & .order-summary,
    & .delivery-summary {
        box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
        border-radius: 10px;

        h3 {
            font-weight: 800;
            font-size: clamp(16px, 1.3vw, 20px);
            padding-top: 1em;
            padding-bottom: 1em;
        }

        p {
            font-size: clamp(16px, 1.3vw, 20px);
        }

        & .card-text {
            padding: 1em 0;
            border-bottom: 1px solid rgba(34, 41, 47, 0.125);
        }
    }
}

.summary-total {
    color: #13B523;
}