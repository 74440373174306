#collect-address-list {
    max-width: 640px;
    margin: 0 auto;
}

.collect-address-item {
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    border-radius: 10px;

    &:hover {
        background-color: rgba(200, 200, 200, 0.2);
        box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
        cursor: pointer;
    }
}

.collect-add-address-btn {
    padding: 0.5em 3em;
    border-radius: 0.4em;
    color: white;
    background-color: #e50019;
    transition: 0.2s transform ease-in-out;

    &:hover {
        filter: brightness(110%);
        transform: scale(1.02);
        cursor: pointer;
    }
}