.cart-summary {

    .summary-heading {
        font-family: "Montserrat", sans-serif;
        font-size: clamp(20px, 3vw, 24px);
        font-weight: 500;
        margin-bottom: 14px;
    }

    .summary-list-container {
        box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
        border-radius: 10px;

        .summary-item-container {
            .card-body {
                @media screen and (max-width: 720px) {
                    max-width: 330px;
                    margin: 0 auto;
                }

                .summary-item {
                    max-width: 640px !important;
                    margin: 0 auto;

                    .summary-tyre-details {
                        .summary-tyre-brand-name {
                            font-weight: 600;
                        }

                        .summary-tyre-name {}

                        .summary-tyre-qty-section {}

                        .summary-title {
                            font-weight: 600;
                        }

                        .summary-text {}
                    }
                }
            }

        }

    }

    .order-summary-mobile {
        display: block;
        width: 100%;
        // max-width: 640px;
        margin: 2em auto 0;

        @media screen and (min-width:1200px) {
            display: none;
        }
    }

    .summary-tyre-name {
        font-size: 22px;
    }
}

#summary-image {
    width: auto !important;
    height: 150px;
    object-fit: contain;

    @media screen and (min-width: 420px) {
        height: 200px;
    }
}

@media screen and (min-width: 1200px) {
    .tyreImage {
        width: 160px !important;
    }
}