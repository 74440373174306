.login-logo-container {
    z-index: 100;
    position: absolute !important;
    width: 500px !important;
    top: 3rem;
    left: 3rem;
    padding: 1rem;
    border-radius: 0.6rem;

    &:hover {
        cursor: pointer;
    }
}

.login-logo {
    width: 500px !important;
}

.login-page-header {
    display: grid;
    background-image: url("../../Assests/HomePage/opencastmine.png");
    background-position: right top;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(30vh, auto);
}

#accountHeader {
    display: none;
    font-size: clamp(1em, 1.5vw, 2.5em);
}

.loginContainer {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: auto;
}

.LoginCover {
    background-image: url("../../Assests/HomePage/logincover.png");
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: right top;
    width: 100%;
    height: auto;
}

.go-back-btn a {
    color: #e50019;
    text-decoration: none;
    text-transform: uppercase;
}

.go-back-btn:hover a {
    text-decoration: underline;
}

@media screen and (max-width: 1200px) {
    .login-page-header {
        display: none;
    }

    #accountHeader {
        display: block;
    }

    .login-logo-container {
        display: none;
    }
}