.reseller-product-details {
    box-sizing: border-box;
    width: 100%;

    & button {
        /* font-family: "Montserrat", sans-serif; */
        background-color: #e50019 !important;
    }

    & .reseller-bid-btn {
        background-color: transparent !important;
        color: black;
        border: 1px solid #e50019;
        font-weight: 600;
    }
}

.reseller-details-content {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 3em;
}

.reseller-product-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    padding: 2em 0 0 0;
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-auto-flow: row;
    grid-template-areas:
        "resellerBrand resellerBrand"
        "resellerTitle resellerTitle"
        "resellerImg resellerImg"
        "resellerPrice resellerStock"
        "resellerQtyControl resellerQtyControl"
        "resellerAddBtn resellerAddBtn"
        "resellerBidBtn resellerBidBtn";
    align-items: center;

    & .reseller-product-brand {
        font-size: clamp(36px, 3vw, 64px);
        font-weight: 700;
        margin: 0;
    }

    & span {
        color: #e50019;
        font-size: clamp(14px, 1.5vw, 18px);
        font-weight: 700;
    }
}

.reseller-cart-btn,
.reseller-bid-btn {
    border-radius: 0.4em;
    background-color: #e50019;
    letter-spacing: 1px;
    color: #fff;
    font-size: clamp(16px, 1.5vw, 22px);
    padding: 0.5em 1em 0.5em 1em;
    margin: 2em 0 0 0;
    font-weight: 300;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    align-self: stretch;
}

.reseller-bid-now-btn {
    border-radius: 0.4em;
    background-color: #e50019;
    letter-spacing: 1px;
    color: #fff;
    font-size: clamp(1em, 1.3vw, 2em);
    padding: 0.2em 1em 0.2em 1em;
    font-weight: 600;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}

.reseller-product-pricing {
    background-color: #ecebeb;
}

.reseller-product-title {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center !important;
}

.reseller-product-title h2 {
    font-size: clamp(1em, 1.5vw, 1.5em);
}

.reseller-product-image {
    grid-area: resellerImg;
    align-self: center;
    justify-self: center;

    & img {
        height: 300px;
        width: auto;
    }
}

.reseller-product-title {
    grid-area: resellerTitle;
}

.reseller-product-brand {
    grid-area: resellerBrand;
}

.reseller-product-stock {
    grid-area: resellerStock;
}

.reseller-product-price {
    grid-area: resellerPrice;
    font-size: 28px !important;
    font-weight: 600;
}

.reseller-cart-item-qty-control {
    grid-area: resellerQtyControl
}

.reseller-cart-btn {
    grid-area: resellerAddBtn;
}

.reseller-bid-btn {
    grid-area: resellerBidBtn;
}

input[type="number"] {
    border-radius: 0.4em;
    width: 100%;
}

.add-to-cart-link {
    color: #e50019;
}

.form-input-btn,
.more-details-btn {
    border-radius: 0.4em !important;
}

@media screen and (max-width: 480px) {
    .reseller-product-image {
        width: 200px;
    }
}

@media screen and (min-width: 481px) {
    .reseller-product-pricing {
        padding: 0 2em 0 2em;
    }
}

@media screen and (min-width: 481px) and (max-width: 960px) {
    .reseller-product-container {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
            "resellerBrand resellerBrand . ."
            "resellerTitle resellerTitle . ."
            ". resellerImg resellerImg ."
            "resellerPrice resellerPrice resellerStock resellerStock"
            "resellerQtyControl resellerQtyControl . ."
            "resellerAddBtn resellerAddBtn resellerBidBtn resellerBidBtn";
    }

    .reseller-product-image {
        width: 270px;
    }
}

@media screen and (min-width: 721px) and (max-width: 960px) {
    .reseller-product-image {
        width: 270px;
    }
}

@media screen and (min-width: 961px) {
    .reseller-product-container {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
            "resellerBrand resellerBrand resellerImg resellerImg"
            "resellerTitle resellerTitle resellerImg resellerImg"
            "resellerPrice resellerStock resellerImg resellerImg"
            "resellerQtyControl resellerQtyControl resellerImg resellerImg"
            "resellerAddBtn resellerBidBtn resellerImg resellerImg";
    }
}