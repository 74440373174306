form {
    box-shadow: none !important;

    .newsletter-input {
        .input-field {
            z-index: 10;

            .input_newsletter {
                border-radius: 20px !important;
            }
        }

        .newsletter-submit-btn {
            z-index: 20;
        }
    }

    & .consentField {
        width: 100%;

        & label {
            color: #fff;
            font-size: clamp(12px, 1.5vw, 15px);
        }

        & .agreement {
            user-select: none;
            /* Chrome all / Safari all */
            -webkit-user-select: none;
            /* Firefox all */
            -moz-user-select: none;
            /* IE 10+ */
            -ms-user-select: none;
        }

        .terms-and-conditions {
            font-size: clamp(12px, 1.5vw, 15px);
            color: #e50119;

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}

.btn-loader {
    transform: rotate(180deg);
    animation: spinner 3s linear infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(720deg);
    }
}