.consentField {
    width: 100%;

    & label {
        color: black;
        // font-size: 15px;
    }

    & .agreement {
        user-select: none;
        /* Chrome all / Safari all */
        -webkit-user-select: none;
        /* Firefox all */
        -moz-user-select: none;
        /* IE 10+ */
        -ms-user-select: none;
    }

    .terms-and-conditions {
        // font-size: 15px;
        color: #e50119;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}