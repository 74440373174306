.cart-content {
    & .cart-total {

        h3 {
            font-weight: 800;
            font-size: clamp(16px, 1.3vw, 20px);
            padding-top: 1em;
            padding-bottom: 1em;
        }

        p {
            font-size: clamp(16px, 1.3vw, 20px);
        }

        & .card-text {
            padding: 1em 0;
            border-top: 1px solid rgba(34, 41, 47, 0.125);
            border-bottom: 1px solid rgba(34, 41, 47, 0.125);
        }
    }
}

.btn-place-order {
    font-family: "Montserrat", sans-serif !important;
    font-size: clamp(14px, 1vw, 20px);
}