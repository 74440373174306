.profile-title {
    font-size: clamp(18px, 1.2vw, 24px);
    font-weight: 600;
}

#profileForm {
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%) !important;
    border-radius: 10px;
}

#companyName,
#companyRegNumber,
#number,
#vatNumber {
    display: block;
    width: 100%;
    color: #000;
}

.form-container {
    margin: 100px auto;
    width: 100%;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 2%;
    right: 3%;
    font-size: 1.5rem;
    z-index: 1;
    color: #fff;
    cursor: pointer;
}

.text-errors {
    font-size: 1.3rem;
    margin-top: 0.5rem;
    color: #f00e0e !important;
}

.profile-form-success {
    text-align: center;
    font-size: 24px;
    margin-top: 80px;
    color: #000;
}

.form-label {
    display: inline-block;
    font-size: 0.8rem;
    margin-bottom: 6px;
    color: #fff;
}

.form-input {
    display: block;
    padding-left: 10px;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 100%;
    border: none;

    &::placeholder {
        color: #595959;
        font-size: 12px;
    }
}

.form-input-btn:hover {
    cursor: pointer;
    background-color: #f00e0e;
    transition: all 0.4s ease-out;
}

#submit-profile {
    display: block;
    color: white;
    background-color: #f00e0e;
    text-transform: initial;
    cursor: pointer;
    text-align: center;
    border-radius: 10px;
    padding: 1em 1.2em;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    transition: 0.2s transform ease-in-out;

    &:hover {
        filter: brightness(110%);
        transform: scale(1.1);
    }

    &:focus {
        border-radius: 0.5em;
    }
}