.delivery-heading {
    font-family: "Montserrat", sans-serif;
    font-size: clamp(20px, 3vw, 24px);
    font-weight: 500;
}

.deliver-go-back-btn {
    border-radius: 10px;

    &:hover {
        color: #e50019;
        background-color: rgba(229, 0, 25, 0.1);
        cursor: pointer;
    }
}

.delivery-address-list {
    display: none;

    @media screen and (min-width: 480px) {
        display: block;
        max-width: 700px;
        margin: 0 auto;
    }
}

.delivery-address-list-mobile {
    width: 100%;

    @media screen and (min-width: 480px) {
        display: none;
    }
}