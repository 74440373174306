.office-details {
    font-size: 28px;
    font-weight: 400;

    & .office-hours {
        // margin-top: 0.6rem;

        // @media screen and (min-width: 768px) {
        //     margin-top: 1rem;
        // }
    }
}

.office-heading,
.form-heading {
    font-size: clamp(24px, 1.4vw, 35px);
    line-height: 3.5rem;
    font-weight: 500;
}

.contact-email-link {
    text-decoration: none;
    color: #75777a;

    &:hover {
        color: #e50019;
    }
}

.submitContactForm {
    padding: 0.5em 1em;
    background-color: #e50019;
    color: #fff;
    font-size: clamp(20px, 1.5vw, 22px);
    border-radius: 0.4em;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
}

.submitContactForm:hover {
    background-color: #ff001a;
    transition: 0.2s all ease-in-out;
}

.form-control::placeholder {
    font-style: normal !important;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-style: normal !important;
}

::-moz-placeholder {
    /* Firefox 19+ */
    font-style: normal !important;
}

:-ms-input-placeholder {
    /* IE 10+ */
    font-style: normal !important;
}

:-moz-placeholder {
    /* Firefox 18- */
    font-style: normal !important;
}

.main-contact-btn {
    padding: 0.8em 1.2em;
    border-radius: 14px;
    color: white;
    background-color: #e50019;
    transition: 0.2s transform ease-in-out;
    font-weight: 600;
    font-family: "Montserrat";

    &:hover {
        cursor: pointer;
        filter: brightness(110%);
        transform: scale(1.02);
    }

    &:focus {
        outline: none;
    }
}

.email-success-container {
    margin: 0 auto;
    max-width: 800px;

    .email-success-icon {
        fill: green;
        color: green;
        padding: 10px;
        justify-content: center;
        align-items: center;
        height: clamp(40px, 6vw, 60px) !important;
    }

    .email-failure-icon {
        fill: red;
        color: red;
        padding: 10px;
        justify-content: center;
        align-items: center;
        height: clamp(40px, 6vw, 60px) !important;
    }

    h1 {
        text-align: center;
        font-size: clamp(18px, 1.4vw, 24px);
    }

    .email-done-btn {
        padding: 0.8em 1.2em;
        border-radius: 10px;
        color: white;
        background-color: #e50019;
        transition: 0.2s transform ease-in-out;
        font-weight: 600;
        font-size: clamp(16px, 1.4vw, 20px);

        &:hover {
            filter: brightness(110%);
            transform: scale(1.02);
            cursor: pointer;
        }
    }
}