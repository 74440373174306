/* Existing styles */
pre {
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    font-family: inherit !important;
    line-height: 1.2;
}

input {
    font-size: 16px !important;
}

  /* Typewriter effect */
.typewriter {
    display: inline-block;
}

.typewriter.animate {
    animation: typing 2s steps(40, end) step-end infinite;
}

@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}


#msg {
    height: calc(100vh - 150px);
}

textarea {
    resize: none;
}

.enhanced-textarea::-webkit-scrollbar {
    width: 8px;
    border-radius: 0%;
}

.enhanced-textarea::-webkit-scrollbar-thumb {
    // background: #ccc;
    border-radius: 8px;
    border-radius: 0%;
}

// .enhanced-textarea::-webkit-scrollbar-thumb:hover {
//     background: #aaa;
// }

/* Styles for the chatbot popup button */
.chatbot-toggle-button {
    position: fixed;
    bottom: 100px;
    right: 40px;
    width: 60px;
    height: 60px;
    background-color: #d91522;
    border: none;
    border-radius: 50%;
    border-bottom-right-radius: 0px;
    color: white;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

/* Styles for the popup chatbot */
.chatbot-container {
    position: fixed;
    bottom: 100px;
    right: 40px;
    width: 350px;
    /* max-width: 90%; */
    height: 500px;
    /* max-height: 70%; */
    background-color: white;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 1000;
}



.chatbot-header {
    padding: 10px;
    background-color: #d91522;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chatbot-header button {
    background: none;
    border: none;
    color: white;
    font-size: 25px;
    cursor: pointer;
}

.chatbot-messages {
    flex-grow: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #f1f1f1;
}

.chatbot-input {
    display: flex;
    align-items: end;
    padding: 10px;
    height: 7rem;
    // border-top: 1px solid #ccc;
}

.chatbot-input textarea {
    flex-grow: 1;
    padding: 5px;
    border: none;
    border-bottom: solid #555 1px;
    border-radius: 0px;
    resize: none;
    outline: none;
    height: 100%;

    &:focus {
        border-bottom: solid black 1px;
    }
}

.chatbot-input button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #d91522;
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    height: 100%;
}

.chatbot-container.hidden {
    display: none;
}

.chatbot-user-message {
    background-color: #d91522;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 0px;
}

.chatbot-system-message {
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 10px;
}

.typing-text {
    animation: pulsate 1.5s infinite linear forwards;
}

@keyframes pulsate {
    0% {opacity: 1;}
    50% {opacity: 0.3;}
    100% {opacity: 1;}
}

@media (max-width: 600px) {
    .chatbot-container {
        width: 100%;
        height: 100%;
        bottom: 0;
        right: 0;
        border-radius: 0;
        padding-bottom: 1rem;
    }

    .chatbot-input {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .chatbot-toggle-button {
        right: 1rem;
        bottom: 160px;
    }
}