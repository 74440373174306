.productsPageHeader {
    display: grid;
    background-image: url("../../Assests/HomePage/home.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(80px, auto);

    @media screen and (min-width: 768px) {
        grid-auto-rows: minmax(250px, auto);
    }
}

.go-to-cart-btn {
    text-decoration: underline;

    &:hover {
        color: #e50019;
    }
}

.banner {
    background-color: #e50019;
    color: #fff;
    font-size: 2em;
    font-weight: bold;
    padding: 0.7em;
}

.partialBanner {
    background-color: #e50119;
    color: #fff;
    width: 100%;
    text-align: center;
    padding: 0.5em;
    font-size: 1.5em;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

.filter-section {
    background-color: #d4d4d4;
}

.searchFilter {
    text-align: left !important;

    form {
        box-shadow: none;
    }

    .filter-title {
        font-size: clamp(20px, 1.5vw, 1.5em);
        color: #3a3a3a;
        font-weight: 500;
    }
}

.reset-filters-btn {
    color: black;
    border: 2px solid #e50019;
    border-radius: 10px;
    letter-spacing: 1px;
    font-size: clamp(14px, 1.5vw, 20px);
    padding: 0.5em 2em !important;
    font-weight: 600;
    transition: 0.2s transform ease-in-out;
    min-height: unset;
    line-height: unset;

    &:hover {
        color: #e50019;
        background-color: rgba(229, 0, 25, 0.1);
        text-decoration: underline;
        cursor: pointer;
    }

    &:focus {
        outline: unset !important;
    }
}

/*dropdown search filter */
.searchround {
    background-color: white;
    color: #3a3a3a;
    border: 0;
    border-radius: 2em;
    display: inline-block;
    font-weight: 600;
    font-size: 1em;
    text-transform: initial;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-shadow: 2px 2px 20px #909090;

    background-image: linear-gradient(45deg, transparent 50%, #fff 50%),
        linear-gradient(135deg, #fff 50%, transparent 50%),
        radial-gradient(#e50119 70%, transparent 72%);
    background-position: calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
    background-repeat: no-repeat;

    &::placeholder {
        font-size: 12px;
    }

    &:focus {
        background-image: linear-gradient(45deg, white 50%, transparent 50%),
            linear-gradient(135deg, transparent 50%, white 50%),
            radial-gradient(#e50119 70%, transparent 72%);
        background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
            calc(100% - 0.5em) 0.5em;
        background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
        background-repeat: no-repeat;
        border-color: #e50119;
        outline: 0;
    }

    & &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;

        & & datalist {
            background-color: red;
            color: #000;
            border: 1px solid red !important;
        }
    }
}

.dropdown-container {
    position: relative;

    .close-filter-btn {
        font-weight: 500;
        font-size: clamp(14px, 2vw, 20px);
        color: rgb(118, 118, 118);
        position: relative;
        float: right;
        z-index: 100;

        &:hover {
            cursor: pointer;
            color: #e50019
        }

        @media screen and (min-width: 768px) {
            position: absolute;
            right: 2em;
        }
    }
}

.search-products-btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    font-size: clamp(15px, 1.5vw, 20px);
    font-weight: 600;
    letter-spacing: 1px;
    background-color: #e50019 !important;
    color: #fff;
    text-decoration: none;
    border-radius: 10px;
    transition: 0.3s all ease-in-out;
    padding: 0.5em 2em !important;
    min-height: unset;
    line-height: unset;

    &:hover {
        cursor: pointer;
        filter: brightness(110%);
        transform: scale(1.02);
    }

    &:focus {
        outline: unset;
    }
}

.filterOptions:hover {
    background-color: #e50119 !important;
    color: #fff !important;
}

/* products grid display */
.bidding-action-container {
    text-align: center;

    & button {
        background-color: #e50119;
        color: #fff;
        padding: 10px;
        font: 20px;
        font-weight: 500;
    }
}

.spec {
    margin-right: 5em;
}

/* search by machine type */
.tabSearch {
    background-color: #e50019;
    width: 100%;
    color: #fff;
    font-size: 1.5em;
    font-weight: 500;
}

.spec-name {
    margin: 1em 0 0 0;
}

.displaying-products-container {
    background-color: #fff;
}

.product-branding {
    background-color: #e50019;

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    z-index: 5;
    max-width: 800px;
    width: 90px;
    height: 100%;
    color: #fff;
    font-weight: 100;
    max-width: 550px;
    width: 90%;

    & h2 {
        font-weight: 500;
        padding: 4% 0 0 8%;
        height: 70px;
        vertical-align: bottom;
    }
}

.column {
    width: 20%;
    padding: 0 10px 0 10px;
    margin: 0 0 50px 0;
    transition: 0.5s all ease-in-out;
}

.showing-product {
    margin-top: 3em 0em;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .filter-container {
        // width: 100%;
        align-self: flex-end;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        .filter-btn {
            margin-right: 2em;
            width: 35px;
            height: 35px;
            color: darkgrey;

            &:focus {
                outline: unset !important;
            }

            span {
                font-weight: 500;
                font-size: clamp(17px, 1.1vw, 20px)
            }

            &:hover {
                cursor: pointer;
                color: #e50019;
            }

            button svg>g>path {
                fill: darkgrey;
            }

            button:hover svg>g>path {
                fill: #e50019;
            }

            @media screen and (min-width: 992px) {
                margin-right: 5em;
            }
        }
    }

    .search-results-container {
        font-size: clamp(20px, 1.4vw, 24px);
        font-weight: 800;
        align-self: flex-start;

        .clear-filter-text {
            font-weight: 400;
            color: #e50019;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}

.card-row {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 1em auto 3em auto;
    text-align: center;
    width: 90%;
}

.tyre-cards {
    background-color: #fff;
    transition: transform 0.2s ease-in-out;
    width: 350px;
    max-width: 370px !important;
    text-align: center;
    padding: 10px;
    transition: 0.3s all ease-in-out;
    position: relative;
    text-decoration: none;
    color: #000;

    &:hover {
        cursor: pointer;
    }


    .tyre-text {
        align-self: flex-start;

        .tyre-brand {
            font-size: clamp(14px, 1.6vw, 22px);
            font-weight: 800;
            text-align: left;
            text-transform: uppercase;
            line-height: 2;
        }

        .tyre-size {
            line-height: 1;
            font-size: clamp(14px, 1.8vw, 22px);
            font-weight: 600;
            text-align: left;
        }

        .stock-price {
            font-size: clamp(12px, 1.6vw, 19px);
            font-weight: 500;
            text-align: left;
        }
    }

    .stock-status {
        position: absolute;
        top: 0.8em;
        left: 1em;

        .has-stock,
        .out-of-stock {
            font-size: clamp(12px, 1vw, 16px);
            color: white;
            padding: 0.4em 0.6em;
            border-radius: 5px;
        }

        .has-stock {
            font-weight: 500;
            background-color: green;
        }

        .out-of-stock {
            background-color: grey;
        }
    }

    @media screen and (min-width: 720px) {
        &:hover {
            -ms-transform: scale(1.1);
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
            border-radius: 20px;
            box-shadow: 1px 1px 10px #75777a;
            background-color: #fff;
            z-index: 9;
        }
    }

    &:hover .cardDivide {
        display: none;
    }

    &:hover .product-description {
        display: block;
    }
}

@media screen and (min-width:340px) {
    .tyre-cards {
        flex: 0 0 50% !important;
    }
}

.addToCart {
    padding: 0.5em 3em;
    color: #fff;
    font-size: clamp(8px, 1vw, 11px);
    border-radius: 0.4em;
    cursor: pointer;
    margin: 0 !important;
    transition: 0.2s transform ease-in-out;

    &:hover {
        filter: brightness(110%);
        transform: scale(1.2);
    }
}

.cardDivide {
    border-bottom: 2px solid #808080;
    width: 50%;
    margin: 0 auto;
    transition: 0.4s all ease-in-out;
}

.product-images {
    display: unset;
    min-width: 100px;
    // width: 150px;
    width: auto;
    height: 150px;

    @media screen and (min-width: 992px) {
        height: 200px;
    }
}

.product-more-details-btn {
    width: 100%;
    padding: 0.7em 1.2em;
    border-radius: 10px;
    color: white;
    background-color: #e50019;
    transition: 0.2s transform ease-in-out;
    font-weight: 700;
    font-family: "Montserrat";

    &:hover {
        filter: brightness(110%);
        transform: scale(1.02);
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }
}

.product-description {
    margin-bottom: 3em;
    display: none;
    transition: 0.5s all ease-in-out;
}

.sign-in-form {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-start;
    align-items: middle;
    padding: 3em;
}

.sign-in-form .form-group {
    margin: 2em 5em 0 0;
}

/* Product Details */
.cancel-btn:hover {
    cursor: pointer;
    color: #e50019;
}

button:focus,
input:focus,
textarea:focus,
select:focus {
    outline: none;
}

.tabs {
    display: block;
    display: -webkit-flex;
    display: -moz-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    overflow: hidden;
}

.tabNav {
    color: #fff;
    background-color: #e50019;
    cursor: pointer;
    display: block;
    font-size: clamp(12px, 1.5vw, 18px);
    font-weight: 500;
    line-height: 1em;
    padding: 2rem;
    text-align: center !important;
    box-shadow: 1px 3px 5px #808080;

    &:hover {
        background-color: #b70f1c;
        font-weight: 600;
    }
}

.tabs [class^="tab"] [type="radio"]:focus,
.tabs [class*=" tab"] [type="radio"]:focus {
    outline: none;
    background-color: #fff !important;
    color: #e50019;
}

.tabs [class^="tab"] [type="radio"],
.tabs [class*=" tab"] [type="radio"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.tabs [class^="tab"] [type="radio"]:checked,
.tabs [class^="tab"] [type="radio"]:checked+div,
.tabs [class*=" tab"] [type="radio"]:checked+div {
    opacity: 1;
}

.tabs [class^="tab"] [type="radio"]+div,
.tabs [class*=" tab"] [type="radio"]+div {
    display: block;
    opacity: 0;
    padding: 2rem 0;
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.tabs .tab-2 {
    width: 50%;
}

.tabs .tab-2 [type="radio"]+div {
    width: 200%;
    margin-left: 200%;
}

.tabs .tab-2 [type="radio"]:checked+div {
    margin-left: 0;
}

.tabs .tab-2:last-child [type="radio"]+div {
    margin-left: 100%;
}

.tabs .tab-2:last-child [type="radio"]:checked+div {
    margin-left: -100%;
}

form {
    width: 90%;
}

@media screen and (max-width: 320px) {
    .card-row {
        display: block !important;
    }

    .searchround {
        margin: 1em 0 3em 0 !important;
    }

    .searchbtn {
        width: 45% !important;
        margin: 0.2em 0 0.2em 1em;
        height: 2.5em;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .searchround {
        margin: 1em 0 3em 0 !important;
        font-size: 1em;
    }

    .searchbtn {
        width: 40% !important;
        margin: 0.2em 0 0.2em 1em;
        height: 2.5em;
    }
}

@media screen and (min-width: 768px) {
    .searchbtn {
        width: 20% !important;
        margin: 0.2em 0 0.2em 1em;
        height: 2.5em;
    }

    form {
        width: 70%;
    }
}