.delivery-mobile-item {
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    border-radius: 10px;

    &:hover {
        background-color: rgba(200, 200, 200, 0.2);
        box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    }

    .select-option-btn {
        padding: 0.8em 1.2em;
        border-radius: 10px;
        color: white;
        background-color: #e50019;
        transition: 0.2s transform ease-in-out;
        font-weight: 500;
        font-size: 15px;

        &:hover {
            filter: brightness(110%);
            transform: scale(1.02);
            cursor: pointer;
        }

        &:focus {
            outline: unset;
        }

        @media screen and (max-width:319px) {
            width: 100%;
        }
    }

    .selected-outline-btn {
        padding: 0.8em 1.2em;
        color: darkgray;
        // border: 2px solid darkgray;
        border-radius: 10px;
        font-size: 15px;
        font-weight: 600;

        // &:hover {
        //     color: #e50019;
        //     background-color: rgba(229, 0, 25, 0.1);
        // }

        &:focus {
            outline: unset;
        }

        @media screen and (max-width:319px) {
            width: 100%;
        }
    }
}