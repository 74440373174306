.make-offer-container {
    margin: 0 auto;
    max-width: 800px;
}

.text-error {
    color: red;
    font-size: .8em;
    font-weight: 600;
    text-align: right;
    text-decoration: none;
}

#reviewForm {
    width: 100% !important;
    border: 1px solid #d4d4d4;
    border-radius: 10px;

    @media screen and (min-width: 768px) {
        max-width: 800px !important;
    }
}

.form-content-right {
    form {
        box-shadow: none;
    }
}

.form-input-btn {
    width: unset !important;
    border-radius: 10px;
    background-color: #e50019;
    letter-spacing: 1px;
    color: #fff;
    font-size: clamp(11px, 2.5vw, 18px) !important;
    padding: 0.5em 1.2em;
    margin-top: 2em;
    font-weight: 600;
    transition: 0.3s all ease-in-out;
    margin-left: auto;

    &:hover {
        filter: brightness(110%);
        transform: scale(1.02);
        cursor: pointer;
    }
}

.email-success-container {
    margin: 0 auto;
    max-width: 800px;

    .email-success-icon {
        fill: green;
        color: green;
        padding: 10px;
        justify-content: center;
        align-items: center;
        height: clamp(40px, 6vw, 60px) !important;
    }

    h1 {
        text-align: center;
        font-size: clamp(18px, 1.4vw, 24px);
    }

    .email-done-btn {
        padding: 0.8em 1.2em;
        border-radius: 10px;
        color: white;
        background-color: #e50019;
        transition: 0.2s transform ease-in-out;
        font-weight: 600;
        font-size: clamp(16px, 1.4vw, 20px);

        &:hover {
            filter: brightness(110%);
            transform: scale(1.02);
            cursor: pointer;
        }
    }

}