$md-radio-checked-color: #e50019;
$md-radio-border-color: rgba(0, 0, 0, 0.54);
$md-radio-size: 20px;
$md-radio-checked-size: 10px;
// $md-radio-ripple-size: 15px;

// @keyframes ripple {

//   0% {
//     box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
//   }

//   50% { 
//     box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
//   }

//   100% {
//     box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
//   }
// }

.radio-btn {
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    border-radius: 10px;
    margin: 10px 0;



    input[type="radio"] {
        display: none;

        &:checked+label:before {
            border-color: $md-radio-checked-color;
            // animation: ripple 0.2s linear forwards;   
        }

        &:checked+label:after {
            transform: scale(1);
        }
    }

    label {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        height: $md-radio-size;
        position: relative;
        padding: 0 ($md-radio-size + 10px);
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;

        &:before,
        &:after {
            position: absolute;
            content: '';
            // border-radius: 50%;
            transition: all .3s ease;
            transition-property: transform, border-color;
        }

        &:before {
            left: 0;
            top: 0;
            width: $md-radio-size;
            height: $md-radio-size;
            border: 2px solid $md-radio-border-color;
        }

        &:after {
            top: $md-radio-size / 2 - $md-radio-checked-size / 2;
            left: $md-radio-size / 2 - $md-radio-checked-size / 2;
            width: $md-radio-checked-size;
            height: $md-radio-checked-size;
            transform: scale(0);
            background: $md-radio-checked-color;
        }
    }

    @media screen and (min-width: 768px) {
        padding: 2rem;
        margin: 16px 0;
    }
}

.radio-btn-selected {
    background-color: rgba(200, 200, 200, 0.2);
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
}