.styled-filter {
    input[type='text']:focus {
        color: white !important;
    }
}

.filter-down-header {
    color: #fff;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-bottom: 1px dotted #fff;
    width: 350px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.filtering-options {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 350px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    & a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    & a:hover {
        font-weight: 600;
        color: #fff;
        background-color: #d91522;
    }

    @media (max-width: 480px) {
        min-width: 242px;
    }
}

.auction-branding {
    background-color: #d91522;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    z-index: 5;
    max-width: 800px;
    width: 98%;
    height: 100%;

    & h2 {
        color: #fff;
        margin: 0 0 0 3em;
        font-weight: 500;
    }
}

.title-xxl span {
    font-weight: 300;
    font-size: 0.9em;
}

.filterIcons {
    display: none;
    margin: 0em 1em 0em 1em !important;
    width: 50px;
    height: auto;
    vertical-align: middle;

    @media screen and (min-width: 420px) {
        display: block;

    }
}

.icons {
    font-size: 5em;
}

.drop-down-header {
    color: #fff;
    font-size: 16px;
    border: none;
    cursor: pointer;
    width: 350px;
    text-align: left;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}

/*filtering dropdown*/
.dropbtn {
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    justify-content: space-evenly !important;

    &:hover,
    &:focus {
        min-width: 350px;
        width: 100%;
        text-align: left;
    }
}

.filterIcon {
    width: 100px;
    height: 20px;
}

#search-value {
    box-sizing: border-box;
    background-position: 14px 12px;
    background-repeat: no-repeat;
    font-size: 16px;
    padding: 14px 20px 12px 45px;
    border: none;
    border-bottom: 2px solid #d91522;
    color: #000;
    min-width: 350px;
    width: 100%;
}

#search-value:focus {
    outline: 1px solid #d91522;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f6f6f6;
    min-width: 230px;
    overflow: auto;
    border: 1px solid #ddd;
    z-index: 1;

    & a {
        background-color: #fff;
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        min-width: 350px;
        width: 100%;
    }
}

.dropdown a:hover {
    background-color: #d91522;
    color: #fff;
}

.show {
    display: block;
}

.selectOption {
    color: #e50019;
    font-size: 5em;
}

.form-body {
    position: relative;

    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
    margin-top: 12px;
    position: relative;
    background-color: #0d141c;

    @media screen and (min-width: 720px) {
        border-bottom-right-radius: 2em;
        border-top-right-radius: 2em;

    }

    @media screen and (min-width: 1200px) {
        &::before {
            content: "";
            top: 0;
            right: -24px;
            height: 100%;
            position: absolute;
            width: 48px;
            -webkit-transform: skew(-10deg);
            -ms-transform: skew(-10deg);
            transform: skew(-10deg);
        }
    }

    @media screen and (min-width: 1400px) {
        width: 90%;

    }

    .filter {
        background-color: #0d141c;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        padding-left: 12px;
        padding-right: 12px;
        border-top-right-radius: 50px;

        @media screen and (min-width: 720px) {
            margin-top: 8px;
            margin-bottom: 8px;
            padding-left: 36px;
            padding-right: 36px;
        }

        @media screen and (min-width: 1200px) {
            margin-top: 16px;
            margin-bottom: 16px;
        }

        @media screen and (min-width: 1400px) {
            padding-left: 72px;
        }

        .facetwp-facet {
            width: 100%;
            margin: 0;

            .fs-wrap {
                border: 0;
                max-width: 100%;

                .fs-dropdown {
                    max-width: -webkit-calc(100% - 24px);
                    max-width: calc(100% - 24px);

                    @media screen and (min-width: 720px) {
                        max-width: -webkit-calc(100% - 72px);
                        max-width: calc(100% - 72px);
                    }

                    @media screen and (min-width: 1200px) {
                        max-width: 375px;
                    }
                }

                .fs-arrow {
                    background-color: transparent;
                    color: #000;

                    &::before {
                        background-size: 16px;
                    }
                }

            }

            .fs-label {
                color: #000;

                @media screen and (min-width: 1200px) {
                    font-size: 18px;
                }
            }
        }

        svg {
            width: 3em;

            @media screen and (min-width: 1200px) {
                justify-content: space-between;
                height: 36px;
                // width: 1em;
                // margin-right: 12px;
                float: right !important;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                vertical-align: bottom !important;
            }
        }

        .filter-active {
            background-color: #f9ab03;
        }

        & select {
            outline: 0;
            padding-left: 12px;
            padding-right: 12px;
            padding-bottom: -15px;
            width: 100%;

            option {
                background-color: #fff !important;
                color: #000;
            }

            @media screen and (min-width: 728px) {
                max-width: 350px;
                font-size: 18px;
            }
        }
    }

    .form-footer {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 36px 0;
        background-color: none;

        @media screen and (min-width: 720px) {
            padding: 12px 0;
            background-color: transparent;
        }

        span {
            color: #fff;
            padding-left: 24px;
            font-size: 12px;

            @media screen and (min-width: 720px) {
                padding-left: 48px;
                font-size: 14px;
            }
        }
    }
}

.homepage-dropdown-icon {
    width: 100%;
    height: 2em;
    background-image: linear-gradient(45deg, transparent 50%, #fff 50%),
        linear-gradient(135deg, #fff 50%, transparent 50%),
        radial-gradient(#e50019, 70%, transparent 72%);
    background-position: calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
    background-repeat: no-repeat;
}

#search-btn-container {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-end;
}

.search-btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    font-size: clamp(15px, 1.5vw, 20px);
    font-weight: 600;
    letter-spacing: 1px;
    background-color: #e50019 !important;
    color: #fff;
    text-decoration: none;
    padding: 0.7em 1.7em;
    cursor: pointer;
    border-radius: 10px;
    margin-bottom: 1em;
    transition: 0.3s all ease-in-out;
    padding: 0.5em 2em !important;

    @media screen and (max-width:420px) {
        margin-right: 2.2rem !important;
    }

    @media screen and (min-width:720px) {
        margin-right: 1rem !important;
    }

}

#choose-size,
#choose-brand,
#choose-machine {
    font-size: clamp(14px, 1.4vw, 16px) !important;
    color: black;
}

#filterField {
    background-color: #0d141c !important;
    min-width: 300px;
    width: 100% !important;
    color: #fff;
    padding: 0.5em;
    font-size: 1em;
    border-radius: 0 !important;
}

#rimid,
#brandid,
#machineTypeid {
    color: #fff;
}