.cartHeader {
    display: grid;
    background-image: url("../../Assests/HomePage/home.png");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(140px, auto);
}

.checkout-content {
    padding: 0em 0.5em;

    @media screen and (min-width: 1199px) {
        padding: 0em 1.5em;
    }
}

.cart-content {
    padding: 1em 0em;

    @media screen and (min-width: 1199px) {
        padding: 2em 5.5em;
    }

    button {
        color: white;
        background-color: #e50019;

        &:hover {
            cursor: pointer;
            filter: brightness(110%);
        }
    }

    .card {
        background-color: white;
        box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
        border-radius: 10px;

        button {
            color: white;
            font-weight: 400;
            width: 100%;
            border-radius: 0.7em;
        }
    }
}

.cart-list {
    flex: 70%;

    .cart-item-container {
        @media screen and (max-width: 1199px) {
            max-width: 500px;
        }

        .tyre-image {
            width: auto !important;
            height: 150px;
            object-fit: contain;

            @media screen and (min-width: 420px) {
                height: 200px;
            }
        }
    }
}

.cart-total {
    flex: 30%;
}

.cart-list h2,
.cart-total h2 {
    font-weight: 400;
    font-size: clamp(18px, 1.7vw, 26px);
}

.go-back-cart {
    width: 100%;
    margin: 0 auto;
}

.go-back-cart-btn {
    color: #e50019;
    display: block;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    & svg>g>path {
        fill: darkgrey;
    }

    &:hover svg>g>path {
        fill: #e50019;
    }
}

@media screen and (min-width: 600px) {
    .cartHeader {
        grid-auto-rows: minmax(100px, auto);
    }
}

.order-summary-md {
    display: none;

    @media screen and (min-width:1199px) {
        display: block;
    }
}