.featuredStockBanner {
    background-color: #e50119;
    width: 100%;
    color: #fff;
    font-size: 2.4em;
    font-weight: 500;
    padding: 0.2em 0 0.2em 0.5em;

    @media screen and (min-width: 720px) {
        padding: 0.5em 0 0.5em 2em;
    }
}

.swiper-stock-container {
    width: 98vw;
    --swiper-navigation-size: 30px;

    @media screen and (min-width: 768px) {
        --swiper-navigation-size: 40px;
    }

    @media screen and (max-width:820px) {
        width: 94vw;
    }

    .swiper-wrapper {
        margin-bottom: 30px;
    }

    .swiper-slide-stock {
        min-height: 40vh;

        // @media screen and (min-width:720px) {
        //     min-height: 86vh;
        // }
    }
}

.featuredView {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
}

.featuredContent {
    -webkit-box-flex: 1 0 21%;
    -moz-box-flex: 1 0 21%;
    -webkit-flex: 1 0 21%;
    -ms-flex: 1 0 21%;
    flex: 1 0 21%;
    margin: 5px;
    height: 100px;
    background-color: blue;
}

.carousel-prev,
.carousel-next {
    color: red !important;
    color: 20px !important;
}

.carouselRow {
    height: auto;
}

:root {
    --surface-color: #fff;
    --curve: 40;
}

.featured-stock-container {
    flex-grow: 1;

    & .cards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 2rem;
        margin: 4rem 5vw;
        padding: 0;
        list-style-type: none;
        height: auto;
    }

    & .card {
        position: relative;
        display: block;
        height: auto;
        border-radius: 10px;
        overflow: hidden;
        text-decoration: none;

        &:hover {
            .card__overlay {
                transform: translateY(0);
                border-radius: 0.4em;
                background-color: #fafafa;
            }
        }

        .card__overlay {
            @media screen and (max-width:1020px) {
                transform: translateY(0) !important;
                border-radius: 0.4em !important;
                background-color: #fafafa !important;
            }
        }
    }
}

.featuredCard {
    height: 320px !important;
}

.card__image {
    width: auto;
    height: 280px;
    margin: 1em auto;
}

.card__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: 0.4em;
    background-color: var(--surface-color);
    transform: translateY(100%);
    transition: 0.2s ease-in-out;
}

.card__header {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    gap: 2em;
    padding: 1em 2em 0em;
    border-radius: calc(var(--curve) * 1px) 0 0 0;
    background-color: #fafafa;
    transition: 0.2s ease-in-out;
}

.card__arc {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1;
    margin: 0 0 -0.05em 0;
}

.card__arc path {
    fill: #fafafa;
    d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}

.card__title {
    font-size: 18px;
    font-weight: 700;
    color: #000;
}

.card__tagline {
    display: block;
    margin: 1em 0;
    font-size: 1.3em;
    color: #000;
}

.card__status {
    font-size: 1em;
    font-weight: 600;
    color: #000;
}

.card__description {
    padding: 0 2em 1em;
    margin: 0;
    color: #000;
    background: #fafafa;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;

    .brand {
        display: block;
        font-size: 18px;
        font-weight: 600;
    }

    .price {
        display: block;
        font-size: 16px;
        font-weight: 500;
    }
}

.featured-more-details-btn {
    font-size: clamp(14px, 1.5vw, 18px);

    flex-shrink: 0;
    letter-spacing: 1px;
    text-align: center;
    background-color: #e50119;
    text-decoration: none;
    font-weight: 400;
    padding: 0.5em 3em;
    border-radius: 0.4em;
    color: white;
    transition: 0.2s transform ease-in-out;

    &:hover {
        filter: brightness(110%);
        transform: scale(1.02);
        cursor: pointer;
    }
}

@media screen and (max-width: 480px) {
    .featured-stock-container .cards {
        display: block !important;
        margin: 0 auto !important;
        justify-content: center !important;

        li {
            margin: 3em 0 3em 0 !important;
            width: 100% !important;
        }
    }

    .carousel-arrow .carousel-prev,
    .carousel-arrow .carousel-prev {
        display: none !important;
    }

    .featuredStockCards {
        display: flex !important;
    }
}

/*
    Override Swiper classes
*/

.swiper-button-next:after,
.swiper-button-prev:after {
    color: #e50119 !important;
}

.bannerText {
    font-size: clamp(30px, 1.4vw, 38px);
    font-weight: 500;
}