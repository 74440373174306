@media (max-width: 768px) {
    .tyre-on-auction {
        display: block !important;
        margin: 5em 0 5em 0;
    }
    .auction-tyre-image {
        width: 100% !important;
        height: 50vh;
    }
    .auction-tyre-spec {
        width: 100% !important;
        padding: 5%;
        font-size: 15px;
    }
    #timer {
        width: 100% !important;
        font-size: 10px;
    }
    .auction-tyre-spec button {
        font-size: 15px !important;
    }
    .auction-tyre-spec p {
        text-align: left !important;
    }
}
.tyre-on-auction {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin: 0 0 0 0;
}
.auction-tyre-image {
    background-color: #dcdcdc;
    width: 45%;
}
.auction-tyre-image img {
    width: 30%;
    display: block;
    margin: 10% auto 10% auto;
}
.auction-tyre-spec {
    background-color: #d4d4d4;
    width: 55%;
    padding: 5%;
}
.auction-tyre-spec h1 {
    font-weight: 500;
}
.auction-tyre-spec h4 {
    margin: -1em 0 3em 0;
    color: #46b130;
}
.auction-tyre-spec h3 {
    margin: 0.5em 0 0 0;
    font-weight: 600;
    font-size: 17px;
}
.part-number {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
.part-number h5 {
    font-size: 1.3em;
}
.part-number span {
    font-size: 1em;
    vertical-align: middle;
    color: #808080;
    padding: 0 0 0 4em;
}
.biddingActions {
    margin: 2em 0 0 0;
}
.biddingActions p {
    border: 1px solid #000;
    font-weight: 700;
    border: 1px solid #000;
    border-radius: 30px;
    padding: 10px;
    /* margin: 2em 1em 0 0; */
}
.biddingActions input {
    border: 1px solid #000;
    border-radius: 1.5em;
    height: 2.9em;
    margin: 0 2em 0 2em;
    padding: 0.7em;
}
.biddingActions input:hover {
    border: 1px solid #d91522;
}
.biddingActions input:focus {
    outline: none;
    border: 1px solid #000;
}
.biddingActions button {
    background-color: #d91522;
    color: #fff;
    font-weight: 600;
    padding: 0.7em 2em 0.7em 2em;
    border-radius: 1.5em;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
    height: 2.9em;
}
.biddingActions button:hover {
    background-color: #a30d19;
}
.biddingActions button:focus {
    outline: 0;
}
@media screen and (max-width: 480px) {
    .biddingActions {
        display: block !important;
    }
}
@media screen and (max-width: 600px) {
    .auction-stock-status {
        font-size: 10px;
    }
}
@media screen and (max-width: 768px) {
    .auction-products-table {
        font-size: 10px;
        padding: 5%;
    }
    .auction-stock-status {
        font-size: 13px;
        width: 50%;
    }
}

.auction-products h1 {
    text-align: center;
    font-size: 40px;
}

.auction-products-table {
    width: 90%;
    justify-content: center;
    overflow-x: auto;
    background-color: #d4d4d4;
    margin: 0 auto 0 auto;
}
.auction-products th {
    background-color: #d30017;
    color: #fff;
    font-weight: 500;
    font-size: 17px;
    padding: 15px;
    text-align: center;
}

.auction-products td {
    text-align: center;
    width: 14.28%;
    border: 1px solid #808080;
}

.auction-products img {
    display: block;
    margin: 10px auto 10px auto;
    width: 50%;
}

.auction-tyre {
    background-color: #fff;
}

.bidding-btn {
    cursor: pointer;
    background-color: #d91522;
    padding: 1em 2em 1em 2em;
    border-radius: 3em;
    color: #fff;
    transition: 0.2s all ease-in-out;
}
.bidding-btn:hover {
    background-color: #b70f1c;
    letter-spacing: 1px;
}
.auction-stock-status {
    width: 90%;
    background-color: #46b130;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 3em !important;
    padding: 1em 2em 1em 2em;
}
/***********second section*************/
.category-auction h1 {
    text-align: center;
    font-size: 50px;
    transition: 0.3s all ease-in-out;
}
.category-nav {
    /* justify-content: center; */
    text-align: center;
    width: 100%;
    height: 10vh;
    float: left;
    margin: 0 0 3em 0;
}
.category-nav button {
    justify-content: center;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    border: 0;
    width: 20%;
    height: 90px;
    transition: 0.3s all ease-in-out;
}
/* tyre brand btns  */
.category-nav :nth-child(1) {
    background-color: #d91522;
}
.category-nav :nth-child(2) {
    background-color: #c8121f;
}
.category-nav :nth-child(3) {
    background-color: #b70f1c;
}
.category-nav :nth-child(4) {
    background-color: #a30d19;
}
.category-nav button:hover {
    letter-spacing: 1px;
}
.category-nav button:focus {
    outline: none;
    background-color: #fff !important;
    color: #d91522;
    box-shadow: 2px 2px 5px #808080;
    font-weight: 600;
}
.auction-tyres {
    box-shadow: 2px 2px 25px #c7c7c7;
    border-radius: 5px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 80%;
    height: auto;
    justify-content: center;
    margin: 0 auto 2em auto;
    transition: 0.8s all ease-in-out;
}
.auction-tyres:hover {
    box-shadow: 1px 1px 10px #d91522;
}
.auction-tyres-properties {
    padding: 1em;
    flex: 1;
    color: #000;
    text-align: left;
}
/* .fa-eye {
    font-size: 48px !important;
    color: #fff;
    background-color: #d30017;
    border-radius: 50%;
    float: right;
    justify-content: center;
    align-items: center;
    margin: 1.5em 1em 0 auto;
} */
.brand-properties {
    display: block;
    margin: 2em auto 2em auto;
    width: 7em;
}
.auction-tyres-properties h5 {
    font-size: 20px;
    margin: 1em 0 0 0;
    color: #1c1c1c;
}
.auction-tyres-properties p {
    margin: 1em 0 1em 0;
    font-weight: 400;
    color: #808080;
}
.auction-tyres-properties span {
    font-weight: 800;
    color: #1c1c1c;
}
@media screen and (max-width: 480px) {
    .category-nav button {
        font-size: 0.6em;
        height: 5em;
    }
    .category-nav button:hover {
        letter-spacing: 0;
    }
    .auction-tyres {
        display: block;
        align-items: center;
        justify-content: center;
        text-align: left !important;
    }
    .auction-tyres p {
        text-align: left;
    }
    .fa-eye {
        display: none;
    }
}
/* test this for use in the tabs  */
