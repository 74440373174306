*,
*:before,
*:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

/* here we need to hide the usual menu and show the mobile menu for all screen sizes less than 600px using media queries using the new when css variable */
.mobileHeader {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    justify-content: space-between;
    padding: 1em;
    width: 100%;

    & span {
        font-weight: 700;
        background-color: none !important;
        padding: 2em;
    }
}

.navBarToggle {
    font-size: 2em;
    color: #fff;
}

.menuMask {
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: 0.4s all ease-in-out;
    opacity: 0.2;
}

.menuDiv {
    height: 80vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    background-color: #000;
    color: #fff;
    top: 0;
    left: 0;
    width: 80%;
    z-index: 2;
    padding: 1em;
    border-bottom-right-radius: 20px;
    transition: 0.4s all ease-in-out;

    & nav ul {
        list-style-type: none;
        line-height: 3em;
        font-size: 1.2em;
        padding-left: 0.1em;
        margin-top: 1em;
    }

    & nav ul li {
        transition: 0.4s all ease-in-out;
        padding-left: 1em;
    }

    & nav ul li:hover {
        background-color: #ee1c1c;
        box-shadow: 1px 1px 10px #ee1c1c;
        color: #fff !important;
    }
}

.menuLinks {
    color: #fff;
    text-decoration: none;
    font-size: clamp(1em, 1.5vw, 1.5em);
}

.usertabs a {
    color: #fff;
    text-decoration: none;
    margin: 0 0 0 0.5em;
}

.mainLogo {
    width: 50%;
    color: #fff;
    opacity: 1 !important;
}

.mobileLogo {
    width: 100%;
}

@media screen and (min-width: 768px) {

    /* hide primary nav */
    .mobileHeader {
        display: none;
    }
}