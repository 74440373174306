.tyre-brand-name {
    filter: opacity(60%);
    font-weight: 500;
}

.remove-cart-item {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 400;

    & button {
        font-size: clamp(14px, 1.2vw, 18px);
        text-transform: uppercase;
        margin-top: 0.5em;
        width: 80%;
        color: black !important;
        background-color: #d4d4d4;
    }

    button span {
        margin-right: 0.2em;
    }

    & span {
        font-size: clamp(16px, 1.3vw, 20px);
    }
}

.tyre-image {
    min-width: 130px;
    width: 170px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    @media screen and (min-width:481px) {
        width: 215px;
    }
}

.tyre-name {
    font-size: clamp(18px, 1.3vw, 22px);
    font-weight: 600;
}

.price-right {
    display: inline-block;
    font-weight: 500;
}

.price-bottom {
    font-weight: 500;
    display: none;
}

@media screen and (min-width: 1200px) {
    .price-right {
        display: none;
    }

    .price-bottom {
        display: inline-block;
    }

    .tyre-qty-section span {
        display: inline-block;
    }

    .tyreImage {
        width: 415px;
    }
}