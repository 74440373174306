.footer-container {
    flex-grow: 1;
    background-color: #0d141c;
    margin-bottom: 80px;

    @media screen and (min-width: 768px) {
        margin-bottom: 0;
    }
}

.pre-footer {
    height: 3em;
    background-color: #e50119;
}

.post-footer {
    background-color: #000;

    &>* {
        color: white;
    }

    .post-footer_links {
        font-size: clamp(12px, 1vw, 16px);
        color: white;
        text-decoration: none;
    }

    .hoverable {
        cursor: pointer;

        &:hover {
            color: #e50119;
        }

        a {
            all: unset;
        }
    }
}

.footer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(300px, auto);
    background-color: #0d141c;
}

.footer>div:nth-child(1) {
    color: white;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(300px, auto);
}

.footer>div:nth-child(2) {
    color: white;
    text-align: center;
}

.footer>div:nth-child(3) {
    color: white;
    text-align: center;
}

.footer>div:nth-child(4) {
    color: white;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(300px, auto);
}

.footer_links:hover {
    color: #e50119;
    cursor: pointer;
}

.logo__img__footer {
    padding-right: 2rem;

    &:hover {
        cursor: pointer;
    }
}

.newsletter-input {

    @media screen and (min-width: 769px) and (max-width: 1024px) {
        display: block !important;
    }

    @media screen and (max-width: 768px) {
        display: block !important;
    }

}

/*********************************Footer section********************************/
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .input_newsletter {
        width: 300px !important;
        height: 40px !important;
        font-size: 15 !important;
    }
}

@media screen and (max-width: 768px) {
    .footer-column {
        font-size: 1em;
    }

    .footer-header {
        font-size: 17px !important;
    }

    .input_newsletter {
        width: 300px !important;
        height: 45px !important;
        font-size: 15 !important;
        padding: 1em !important;
    }

    .input_newsletter::placeholder {
        font-size: 14px;
    }

}

@media screen and (max-width: 600px) {
    .input_newsletter {
        width: 200px;
    }
}

@media screen and (max-width: 480px) {
    .logo__img__footer {
        width: 70%;
    }

    .footer-column {
        width: 100% !important;
        padding: 0 !important;
    }

    .footer-header {
        font-size: 20px !important;
    }

    .input_newsletter {
        width: 100% !important;
        height: 40px !important;
        font-size: 15 !important;
    }
}

.footer-card {
    // padding: 16px;
    // margin: 3em 0 3em 0;
    text-align: left;
}

.footer-header {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
}

.footer_links {
    color: #fff;
    text-decoration: none;
}

.footer_links:hover {
    color: #e50019;
}

.footer-email {
    font-weight: 400;
}

.footer-email,
.footer-contact {
    color: #fff;
}

.footer-email:hover,
.footer-contact:hover {
    color: #e50019;
}

.input_newsletter {
    background-color: white;
    font-family: "Montserrat", sans-serif !important;
    color: #000;
    border-radius: 25px;
    height: 45px;
    width: 300px;
    padding-left: 20px;
}

.input_newsletter:focus {
    outline: none !important;
    border: 1px solid #e50019;
    box-shadow: 0 0 10px #e50019;
    height: 45px;
    font-size: clamp(14px, 1.2vw, 16px);
}

.btn_newsletter {
    color: #fff;
    background-color: #e50019;
    font-family: "Montserrat", sans-serif !important;
    font-weight: 200;
    border-radius: 25px;
    height: 34px;
    cursor: pointer;
    transition: 0.2s transform ease-in-out;
    font-weight: 400;
    z-index: 2;
    font-size: clamp(14px, 1.2vw, 16px);
    width: 100%;

    &:hover {
        filter: brightness(110%);
        transform: scale(1.02);
        cursor: pointer;
    }

    @media screen and (min-width: 720px) {
        height: 45px;
        margin-top: 0;
        margin-left: -25px;
        width: 100px;
        // margin: 0.5em 0em;
    }


}

.main-btn {
    padding: 0.5em 3em;
    border-radius: 0.4em;
    color: white;
    background-color: #e50019;
    transition: 0.2s transform ease-in-out;

    &:hover {
        filter: brightness(110%);
        transform: scale(1.02);
        cursor: pointer;
    }
}