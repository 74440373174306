.form-container {
    margin: 0 !important;
    background-color: #fff;
}

.contact-page-header {
    display: grid;
    background-image: url("../../Assests/HomePage/opencastmine.png");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    // background-attachment: fixed;
    grid-template-columns: 1fr;
}

.common-header h1 {
    font-size: clamp(25px, 5vw, 40px) !important;
    max-width: 29ch !important;
}

.office-hours {
    color: #75777a;
    font-size: 15px;
}

.contact-form {
    padding: 0 10% 60% 10%;
}

.form-label {
    color: #000;
    font-weight: 500;
}

.companyAndPhone {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

#phoneNumber {
    width: 100%;
}

#companyName {
    width: 100%;
}

form {
    width: 80%;
}

.form-control {
    border: 2.5px solid #d4d4d4;
    border-radius: 10px !important;
}

.form-check {
    display: block;

    &>*:hover {
        cursor: pointer;
    }
}

input[type="radio"]:checked {
    background-color: #d30017;
}

#contact-textarea {
    border: 1px solid #d4d4d4;
    width: 100%;
    border-radius: 10px;
}

#contact-textarea:hover {
    border-color: #e50019;
}

#contact-textarea:focus {
    outline: none;
    border: 1px solid #000;
}

#submit-contact-us {
    display: block;
    width: 100%;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    border-radius: 10px;
    margin: 0 0 5em 0;
}

#submit-reviews {
    display: block;
    width: 100%;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    transition: 0.3s all ease-in-out;

    &:hover {
        letter-spacing: 1px;
    }
}

.h1 {
    font-size: 25px !important;
}

@media screen and (max-width: 480px) {
    .common-header h1 {
        line-height: 2em;
        text-transform: uppercase;
    }

    .form-header p {
        text-align: left !important;
    }

    .companyAndPhone {
        display: block;
    }

    .form-control {
        width: 100% !important;
    }
}

@media screen and (min-width: 481px) {
    .form-header p {
        text-align: left !important;
    }
}