button {
    background-color: #cfcfcf;
    border-radius: 10px;
    text-align: center;
    padding: 0.8em 1.5em;
    // width: 20%;
    font-size: 18px;
    font-weight: 600;
    transition: 0.2s all ease-in-out;
    cursor: pointer;

    &:hover {
        // background-color: #75777a;
        cursor: pointer;
        filter: brightness(110%);
        transform: scale(1.02);
    }

    &:focus {
        outline: none;
        border: 0;
    }
}