.orders-title {
    font-size: clamp(18px, 5vw, 24px);
    font-weight: 600;
}

// #table {
//     box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
//     border-radius: 10px;
//     font-size: 24px;

//     thead {
//         border-bottom: 1px solid rgba(10, 10, 10, 0.1);

//         tr th {
//             font-size: clamp(18px, 1.2vw, 20px);
//             font-weight: 500;
//         }
//     }

//     tbody {
//         font-size: 18px;

//         tr {
//             background-color: rgba(0, 0, 0, 0.05);

//             &:hover {
//                 box-shadow: 0px 0px 19px -10px #111;
//             }

//             &:nth-of-type(2n) {
//                 background-color: rgba(0, 0, 0, 0.1);

//                 &:hover {
//                     background-color: rgba(0, 0, 0, 0.08) !important;
//                 }
//             }

//             td {
//                 margin-right: 1em;

//                 @media screen and (max-width: 768px) {
//                     text-align: right !important;
//                 }
//             }
//         }
//     }
// }

// th,
// td {
//     padding: 0.5em 0.5em !important;
//     text-align: center;

//     @media screen and (min-width: 768px) {
//         padding: 1em 0.5em !important;
//     }
// }

// th.pr-md-3 {
//     text-align: center !important;
// }

// thead tr,
// tr:nth-child(2n) {
//     background-color: rgb(248, 248, 248);
// }

// .table>thead:first-child>tr:first-child>th {
//     border-top: none;
// }

// tbody:last-child>tr:last-child>td {
//     border-bottom: none;
// }

// @media only screen and (max-width: 720px) {
//     tr {
//         position: relative;
//     }

//     td::before {
//         content: attr(data-label) !important;
//         position: absolute;
//         top: 50% !important;
//         transform: translateY(-50%);
//         padding-left: 10px;
//         white-space: nowrap;
//     }

//     tbody>tr>td {
//         border-bottom: 1px solid #d4d4d4 !important;
//     }
// }

.processing {
    color: darkgrey;

    &::before {
        color: black;
    }
}

.shipped {
    color: orange;

    &::before {
        color: black;
    }
}

.delivered {
    color: green;

    &::before {
        color: black;
    }
}