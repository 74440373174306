#not-found-container {
    background-image: url("../../Assests/404/background_404.png");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    font-family: "Montserrat", sans-serif;
}

.not-found-text {
    display: block;
    color: #e50019;
    // font-weight: 500;
    font-size: clamp(14px, 1.5vw, 24px);
}

.minestar-loader {
    width: clamp(80px, 22vw, 200px);
}

#not-found-content {
    padding: 1em;

    #not-found-header {
        color: #e50019;
        font-weight: 800;
        font-size: clamp(60px, 10vw, 160px);
    }


    #not-found-text {
        display: block;
        color: #e50019;
        font-weight: 700;
        font-size: clamp(14px, 1.1vw, 24px);
    }

    #not-found-redirect-btn {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        font-size: clamp(18px, 1.5vw, 22px);
        letter-spacing: 1px;
        background-color: #e50019 !important;
        color: #fff;
        padding: 0.5em 1.7em;
        cursor: pointer;
        border-radius: 0.4em;

        transition: 0.2s ease-out transform;

        &:hover {
            filter: brightness(110%);
            transform: scale(1.02);
        }
    }

    .not-found-truck {
        width: clamp(290px, 50vw, 600px);
    }
}