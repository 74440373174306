.text-error {
    color: red;
    font-size: .8em;
    font-weight: 600;
    text-align: right;
    text-decoration: none;

}

#reviewForm {
    width: 100% !important;
    border: 1px solid #d4d4d4;
    border-radius: 10px;

    @media screen and (min-width: 768px) {
        max-width: 800px !important;
    }
}

.form-content-right {
    form {
        box-shadow: none;
    }
}

.form-input-btn {
    grid-area: addBtn;
    width: unset !important;
    border-radius: 10px;
    background-color: #e50019;
    letter-spacing: 1px;
    color: #fff;
    // font-size: clamp(16px, 1.5vw, 22px) !important;
    font-size: clamp(11px, 2.5vw, 18px) !important;
    padding: 0.5em 1.2em;
    margin-top: 2em;
    font-weight: 600;
    transition: 0.3s all ease-in-out;
    margin-left: auto;

    &:hover {
        filter: brightness(110%);
        transform: scale(1.02);
        cursor: pointer;
    }
}