.form-container {
    width: 100%;
    position: relative;
    height: 100vh;
    display: grid;
}

.form-img {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & h1 {
        font-size: clamp(14px, 1.5vw, 22px);
        text-align: left !important;
        width: 80%;
        margin-bottom: 1rem;
        color: #000;
        font-weight: 500;
    }
}

.form-inputs {
    margin-bottom: 0.5rem;
}

.text-errors {
    font-size: 1.3rem;
    margin-top: 0.5rem;
    color: #e50019 !important;
}

.form-label {
    display: inline-block;
    font-size: 0.8rem;
    margin-bottom: 6px;
    color: #fff;
}

.form-input {
    display: block;
    padding-left: 10px;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 100%;
    border: none;

    &::placeholder {
        color: #595959;
        font-size: 12px;
    }
}

.form-input-btn {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    border-radius: 2px;
    background-color: #e50019;
    outline: none;
    border: none;
    color: #fff;
    font-size: clamp(16px, 1.3vw, 18px);

    &:hover {
        cursor: pointer;
        background-color: #e50019;
        transition: all 0.4s ease-out;
    }
}

.form-input-login {
    font-size: 0.8rem;
    margin-top: 10px;
    color: #fff;
    width: 100%;
    text-align: center;

    & a {
        text-decoration: none;
        color: #27cdff;
        font-weight: 600;
    }
}

.loginForm h1 {
    line-height: 4rem;
}

.max-height {
    height: 100vh;
}

.add-address-btn {
    padding: 0.5em 3em;
    border-radius: 0.4em;
    color: white;
    background-color: #e50019;
    transition: 0.2s transform ease-in-out;
    letter-spacing: 1px;
    font-weight: 600;

    &:hover {
        filter: brightness(110%);
        transform: scale(1.02);
        cursor: pointer;
    }
}

.register-btn {
    width: 100%;
    padding: 0.5em 0.8em;
    font-size: clamp(16px, 1.3vw, 18px);
    border-radius: 0.4em;
    color: white;
    background-color: #e50019;
    transition: 0.2s transform ease-in-out;
    letter-spacing: 1px;
    font-weight: 600;

    &:hover {
        filter: brightness(110%);
        transform: scale(1.02);
        cursor: pointer;
    }
}

.login-redirect {
    color: black;
    border: 2px solid #e50019;
    border-radius: 10px;
    letter-spacing: 1px;
    font-size: clamp(16px, 1.3vw, 18px);
    font-weight: 600;
    width: 100%;
    height: 40px;
    cursor: pointer;
    padding: 0.5em 0.8em;

    &:hover {
        color: #e50019;
        background-color: rgba(229, 0, 25, 0.1);
    }

    &:focus {
        outline: none;
        transform: scale(0.98);
    }
}

#bAcceptTsAndCs-label:hover {
    cursor: pointer;
}