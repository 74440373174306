/* here we need to hide the usual menu and show the mobile menu for all screen sizes less than 600px using media queries using the new when css variable */
.mobileHeader {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    padding: 1em;
    width: 100%;

    & span {
        font-weight: 700;
        background-color: none !important;
        padding: 2em;
    }
}

.navBarToggle {
    font-size: 2em;
    color: #fff;
}

.menuMask {
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: 0.4s all ease-in-out;
    opacity: 0.2;
}

.menuDiv {
    position: fixed;
    background-color: #000;
    color: #fff;
    top: 0;
    left: 0;
    width: 80%;
    z-index: 2;
    padding: 1em;
    transition: 0.4s all ease-in-out;
    opacity: 1;

    & nav ul {
        list-style-type: none;
        line-height: 3em;
        font-size: 1.2em;
        padding: 0 0 0 0.1em;
        margin: 2em 0 0 0;

        li {
            transition: 0.4s all ease-in-out;
            padding-left: 1em;

            &:hover {
                background-color: #ee1c1c;
                box-shadow: 1px 1px 10px #ee1c1c;
                color: #fff !important;
            }
        }
    }
}

.menuLinks {
    color: #fff;
    text-decoration: none;
    font-size: clamp(1em, 1.5vw, 1.5em);
}

.usertabs a {
    color: #fff;
    text-decoration: none;
    margin: 0 0 0 0.5em;
}

.mainLogo {
    width: 50%;
    color: #fff;
    opacity: 1 !important;
}

.mobileLogo {
    width: 100%;
}

.is-active-nav-item {
    color: #d91522 !important;
}

.stock-mobile-dropdown-menu {
    position: relative !important;
    background-color: white;

    & ul {
        margin: 0 !important;
        padding: 0 !important;
    }
}

.stock-mobile-item {
    font-size: 18px;
    cursor: pointer;
    color: black;

    &:hover {
        background-color: black !important;
        color: white !important;
        box-shadow: none !important;
    }
}

#stock-mobile-navbar:hover {
    background-color: #d91522;
}

.stock-mobile-navbar-item svg {
    width: 15px;
    height: 15px;
    margin-left: 0.3em;
}

@media screen and (min-width: 768px) {
    .mobileHeader {
        display: none;
    }
}

.bottom-nav-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    width: 100%;
    background-color: #c4121e;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    z-index: 100;

    .menu-links {
        color: #fff;
        text-decoration: none;
        height: 100%;
    }

    .is-bottom-active-nav-item {
        background-color: #910e17;
        color: white !important;

        &:hover {
            background-color: #910e17 !important;
            cursor: default;
        }

    }

    .home-bottom,
    .product-bottom,
    .cart-bottom {
        font-weight: 600;
        color: white;
        text-align: center;
        width: 100%;

        display: grid;
        place-items: center;

        &:hover {
            background-color: #da1825;
        }
    }

    .home-bottom {
        border-right: 1px solid white;
    }

    .cart-bottom {
        border-left: 1px solid white;
    }
}