.policy-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        width: 90%;

        @media (min-width: 768px) {
            width: 70%;
        }

        ul {
            list-style-type: disc;

            li {
                margin-left: 16px;
            }
        }
    }
}