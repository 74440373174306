.productDetails {
    box-sizing: border-box;
    width: 100%;

    & .detailsContent {
        width: 100%;
        display: grid !important;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-auto-flow: row;
        grid-template-areas:
            "details"
            "specs"
            "banner"
            "request";

        @media screen and (min-width: 960px) {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: auto;
            grid-auto-flow: row;
            grid-template-areas:
                "details details specs"
                "banner banner specs"
                "request request specs";
        }

        .productPricing {
            grid-area: details;
            background-color: #ecebeb;
            padding: 1em;

            .productDetailsContainer {
                max-width: 600px;
                margin: 0px auto;
                display: grid !important;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: auto;
                grid-auto-flow: row;
                grid-template-areas:
                    "brand brand"
                    "title title"
                    "img img"
                    "stock price"
                    ". qtyControl"
                    "addBtn addBtn"
                    "bidBtn bidBtn";

                @media screen and (min-width: 481px) and (max-width: 960px) {
                    grid-template-columns: repeat(4, 1fr);
                    grid-template-areas:
                        "brand brand brand brand"
                        "title title title title"
                        "img img img img"
                        "stock stock price price"
                        ". . qtyControl qtyControl"
                        "bidBtn bidBtn addBtn addBtn";
                }

                @media screen and (min-width: 961px) {
                    grid-template-columns: repeat(4, 1fr);
                    grid-template-areas:
                        ". . img img"
                        "title title img img"
                        "brand brand img img"
                        "stock stock img img"
                        "price price img img"
                        "qtyControl . img img"
                        ". . img img"
                        "addBtn addBtn bidBtn bidBtn";
                }

                @media screen and (min-width:1400px) {
                    max-width: 800px;
                }


                span {
                    color: #e50019;
                    font-size: 20px;
                    font-weight: 700;

                    @media screen and (min-width:960px) {
                        font-size: 14px;
                    }
                }

                .productTitle {
                    grid-area: title;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    align-items: center !important;

                    h1 {
                        font-size: clamp(14px, 3vw, 18px);
                    }
                }

                .productBrand {
                    grid-area: brand;
                    font-size: clamp(18px, 3vw, 64px);
                    font-weight: 700;
                    margin: 0;
                }

                .productStock {
                    grid-area: stock;
                }

                .productPrice {
                    grid-area: price;
                    font-weight: 600;

                    @media screen and (max-width: 960px) {
                        text-align: right;
                    }
                }

                .productStock,
                .productPrice {
                    font-size: clamp(14px, 3vw, 18px) !important;
                }

                .cart-item-qty-control {
                    grid-area: qtyControl;
                    font-size: clamp(12px, 3vw, 16px);
                }

                .addToCartBtn {
                    grid-area: addBtn;
                    background-color: #e50019;
                    color: #fff;
                    transition: 0.3s transform ease-in-out;

                    &:hover {
                        filter: brightness(110%);
                        transform: scale(1.02);
                    }
                }

                .bidOfferBtn {
                    grid-area: bidBtn;
                    border: 2px solid #e50019;
                    color: #e50019;

                    &:hover {
                        text-decoration: underline;
                        background-color: rgba(229, 0, 25, 0.1);
                    }
                }

                .addToCartBtn,
                .bidOfferBtn {
                    border-radius: 10px;
                    letter-spacing: 1px;
                    font-size: clamp(16px, 2.5vw, 18px) !important;
                    padding: 0.5em 1em;
                    font-weight: 600;
                    cursor: pointer;
                    margin-top: 0.5em !important;

                    @media screen and (min-width: 480px) {
                        margin-top: 1em !important;
                    }
                }

                .bidNowBtn {
                    border-radius: 0.4em;
                    background-color: #e50019;
                    letter-spacing: 1px;
                    color: #fff;
                    font-size: clamp(1em, 3vw, 2em);
                    padding: 0.2em 1em;
                    font-weight: 500;
                    transition: 0.3s all ease-in-out;
                    cursor: pointer;
                }

                .productImage {
                    grid-area: img;
                    align-self: center;
                    justify-self: center;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .productDetailImage {
                        width: auto;
                        height: 150px;

                        @media screen and (min-width: 480px) {
                            height: 180px;
                        }

                        @media screen and (min-width: 768px) {
                            height: 200px;
                        }

                        @media screen and (min-width:1199px) {
                            // height: 300px;
                            height: 100%;
                        }
                    }
                }
            }

            .otherOffers {
                background-color: #ecebeb;
                box-shadow: 0px 0px 8px 4px lightgray;
                border-radius: 1rem;
                width: 100%;

                @media screen and (min-width: 455px) {
                    width: 400px;
                }

                @media screen and (min-width:720px) {
                    width: 600px
                }

                h3 {
                    font-size: clamp(13px, 2vw, 22px);
                    font-weight: 700;
                }

                .other-offers-header {
                    h3 {
                        font-size: clamp(14px, 2vw, 18px) !important;
                        font-weight: 600;
                    }
                }

                .view-reseller-btn {
                    font-size: clamp(14px, 2vw, 16px);
                    text-transform: uppercase;
                    color: #e50019;
                    font-weight: 600;

                    @media screen and (min-width:720px) {
                        font-weight: 500;
                        padding: 0.5em 1em;
                        color: white;
                        background-color: #e50019;
                        border-radius: 10px;
                    }


                    &:hover {
                        transform: scale(1.02);
                        filter: brightness(110%);
                        cursor: pointer;
                    }

                    &:active {
                        transform: scale(0.98);
                    }

                    &:focus {
                        outline: unset;
                    }
                }

                .otherOfferItem {
                    border-radius: 5px;
                    padding: 4px;

                    @media screen and (max-width:720px) {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 0px;
                        padding-bottom: 4px;
                    }

                    &>p {
                        font-size: clamp(14px, 2vw, 16px);
                    }

                    &:hover {
                        background-color: white;
                    }
                }
            }

            .reseller-condition {
                @media screen and (max-width:720px) {
                    display: none;
                }
            }
        }

        .banner {
            grid-area: banner;
            max-height: 48px;
        }

        .request-form {
            grid-area: request;
            place-self: center;
            max-width: 900px;
        }

        .productSpecification {
            grid-area: specs;

            .tyreSpecifications {
                &> :not(:first-child) {
                    border-top: 1px solid rgba(100, 100, 100, 0.2);
                }

                &> :hover {
                    background-color: rgba(100, 100, 100, 0.05);
                }

                .specItem {
                    font-size: clamp(14px, 3vw, 16px);

                    p {
                        font-weight: 700;
                    }

                    span {
                        font-weight: 500;

                    }

                    .category-list {
                        text-align: right;
                    }
                }
            }
        }
    }
}

input[type="number"] {
    border-radius: 0.4em;
    width: 100%;
}

.add-to-cart-link {
    color: #e50019;
}

.form-input-btn,
.more-details-btn {
    border-radius: 0.4em !important;
}

.active-radio-btn {
    background-color: #b70f1c;
    font-weight: 600;
    cursor: default;
}