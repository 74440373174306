.input-section {
    position: relative;

    & button {
        padding: 0 !important;
        width: 20% !important;
        font-size: 1.2em !important;
        background-color: #e50019 !important;
        color: white !important;
        font-weight: 400 !important;
        border-radius: 0.7em !important;
    }

    & .tyre-qty-input {
        color: black;
        background-color: lightgray;
        width: 60%;
        border-radius: 8px;
    }

    & .tyre-qty-input:hover,
    & .tyre-qty-input:focus-within {
        filter: brightness(110%);
    }

    & button:hover {
        cursor: pointer;
        filter: brightness(110%);
    }
}

.tyre-qty-increment-btn {
    margin-left: -10px;
}

.tyre-qty-decrement-btn {
    margin-right: -10px;
}

.tyre-qty-increment-btn,
.tyre-qty-decrement-btn {
    z-index: 10;
    font-size: 1em;
}

.tyre-qty-section span {
    display: none;

    @media screen and (min-width: 1200px) {
        display: inline-block;
    }
}